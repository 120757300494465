import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Toolbar,
  IconButton,
  Stack,
  Divider,
  InputLabel,
  TextField
} from "@mui/material";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import {
  addUsbDetails,
  fetchUsbById,
  updateUsbDetails
} from "../../API/usbBlocking";
import FormShimmering from "../utility/FormShimmer";
import CloseIcon from "@mui/icons-material/Close";
import BeenhereOutlinedIcon from "@mui/icons-material/BeenhereOutlined";
import AddIcon from "@mui/icons-material/Add";
import CustomTooltip from "../../utils/Tooltip";

function UsbForm({
  usbType,
  setFeedback,
  setOpen,
  open,
  selectedUsbId,
  setFetchCount,
  updateTableData
}) {
  let formType = selectedUsbId ? "Update" : "Add";

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);

    if (formType === "Update") {
      fetchUsbById(selectedUsbId)
        .then((response) => {
          formik.setValues(response.data);
        })
        .catch((error) => console.log(error.response))
        .finally(() => {
          setLoading(false);
        });
    } else {
      formik.setValues(formik.initialValues);
      setLoading(false);
    }
  }, [selectedUsbId, open]);

  const validate = Yup.object({
    name: Yup.string()
      .max(20, "Must be 20 characters or less")
      .required("Required"),
    productId: Yup.string().required("Required"),
    vendorId: Yup.string().required("Required")
  });

  const formik = useFormik({
    initialValues: {
      id: "",
      name: "",
      productId: "",
      vendorId: ""
    },
    validationSchema: validate,
    onSubmit: (values, { resetForm }) => {
      submitForm(values, resetForm);
    }
  });

  function submitForm(values, resetForm) {
    let promise;
    if (formType === "Add") {
      addUsbDetails({ ...values, type:usbType.type })
        .then((response) => {
          setFeedback({
            severity: "success",
            message: `USB Added Successfully`,
            state: true
          });
          setFetchCount((fetchCount) => fetchCount + 1);
          setOpen(false);
        })
        .catch(function (error) {
          setFeedback({
            severity: "error",
            message: error.response.data,
            state: true
          });
        });
    } else {
      const jsonPatch = [
        {
          op: "replace",
          path: "/productId",
          value: values.productId
        },
        {
          op: "replace",
          path: "/name",
          value: values.name
        },
        {
          op: "replace",
          path: "/vendorId",
          value: values.vendorId
        }
      ];

      promise = updateUsbDetails(selectedUsbId, jsonPatch)
        .then((response) => {
          if (response && response.data) {
            setFeedback({
              severity: "success",
              message: "USB updated successfully",
              state: true
            });
            setOpen(false);
            resetForm(response.data);
            updateTableData(response.data);
          }
        })
        .catch((error) => {
          setFeedback({
            severity: "error",
            message: "Something went wrong",
            state: true
          });
        });
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div
      className={`drawer-form-wrapper ${
        open ? "drawer-form-open" : "drawer-form-closed"
      }`}
    >
      <Toolbar />
      <br />
      <div className="close-button">
        <IconButton onClick={handleClose} sx={{ padding: 0 }}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className="drawer-form-container">
        {loading ? (
          <FormShimmering />
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <h5>{formType} {usbType.title}</h5>
            <Divider />
            <div className="form-input">
              <InputLabel htmlFor="name" className="form-input-label" required>
                Name
              </InputLabel>
              <TextField
                size="small"
                id="name"
                placeholder="Name"
                className="form-textfield"
                fullWidth
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </div>
            <div className="form-input">
              <div style={{ display: "flex", alignItems: "center" }}>
                <InputLabel
                  htmlFor="productId"
                  className="form-input-label"
                  required
                >
                  Product Id
                </InputLabel>
                <CustomTooltip title="Locate the Product ID in Device Manager under Properties > Details > Hardware IDs."></CustomTooltip>
              </div>
              <TextField
                size="small"
                id="productId"
                placeholder="Product ID"
                className="form-textfield"
                fullWidth
                name="productId"
                value={formik.values.productId}
                onChange={formik.handleChange}
                error={
                  formik.touched.productId && Boolean(formik.errors.productId)
                }
                helperText={formik.touched.productId && formik.errors.productId}
              />
            </div>
            <div className="form-input">
              <div style={{ display: "flex", alignItems: "center" }}>
                <InputLabel
                  htmlFor="vendorId"
                  className="form-input-label"
                  required
                >
                  Vendor Id
                  <CustomTooltip title="Locate the Vendor ID in Device Manager under Properties > Details > Hardware IDs."></CustomTooltip>
                </InputLabel>

              </div>
              <TextField
                size="small"
                id="vendorId"
                placeholder="Vendor Id"
                className="form-textfield"
                fullWidth
                name="vendorId"
                value={formik.values.vendorId}
                onChange={formik.handleChange}
                error={
                  formik.touched.vendorId && Boolean(formik.errors.vendorId)
                }
                helperText={formik.touched.vendorId && formik.errors.vendorId}
              />
            </div>

            <br />
            <Stack spacing={2} direction="row" justifyContent="flex-end">
              <Button
                className="primary-button-outlined"
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                className="primary-button-filled"
                variant="contained"
                type="submit"
                startIcon={
                  formType === "Update" ? <BeenhereOutlinedIcon /> : <AddIcon />
                }
              >
                {formType}
              </Button>
            </Stack>
          </form>
        )}
      </div>
    </div>
  );
}

export default UsbForm;
