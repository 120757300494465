import { useState, useEffect } from "react";
import TableComponent from "../utility/Table";
import { Button, Box, Typography } from "@mui/material";
import {
  deleteGroup,
  fetchAllGroupsByTypeAndPagination
} from "../../API/group";
import { fetchDevices, togglecanRemoveAgent } from "../../API/devices";
import GroupForm from "./GroupForm";
import { useSelector } from "react-redux";
import "./device-group.css";
import PageTitle from "../utility/PageTitle";
import SearchBar from "../utility/SearchBar";
import { DIALOG_TYPES, MESSAGES } from "../../utils/Constants";
import CustomDialog from "../utility/Dialog";
import DevicesIcon from "@mui/icons-material/Devices";
import { useLocation } from "react-router-dom";
import Pagination from "../utility/Pagination";
import CreateButton from "../utility/CreateButton";
import { PRIVILEGES } from "../../utils/Privileges";

const label = { inputProps: { "aria-label": "Switch demo" } };

function DeviceGroupList({ setFeedback }) {
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [data, setData] = useState(null);
  const [fetchCount, setFetchCount] = useState(0);
  const [devicesData, setDevicesData] = useState([]);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const user = useSelector(({ auth }) => auth.currentUser);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState("");

  const { state } = useLocation();

  useEffect(() => {
    if (state?.groupId) {
      setSelectedId(state.groupId);
      setOpen(true);
    }
  }, [state]);

  useEffect(() => {
    fetchAllGroupsByTypeAndPagination(
      "DEVICE",
      pageSize,
      pageNumber,
      searchText
    )
      .then(({ data }) => {
        setData(data.GroupList);
        setTotalCount(data.totalCount);
      })
      .catch((error) => console.log(error.response.data));
  }, [fetchCount, pageNumber, searchText, pageSize]);

  const handleClickOpen = () => {
    setOpen(true);
    setSelectedId(null);
  };

  const handleDelete = (id) => {
    setIsDeleteConfirmationOpen(true);
    setSelectedId(id);
  };

  const handleConfirmDelete = (id) => {
    deleteGroup(selectedId)
      .then(() => {
        setData(data.filter((group) => group.id !== selectedId));
        setFeedback({
          severity: "success",
          message: "Group deleted successfully",
          state: true
        });
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: MESSAGES.GENERIC_EXCEPTION_MESSAGE,
          state: true
        });
      })
      .finally(() => {
        setIsDeleteConfirmationOpen(false);
        setSelectedId("");
      });
  };

  const handleEdit = (id) => {
    setOpen(true);
    setSelectedId(id);
  };

  const handleToggleStatus = (id, event) => {
    const isChecked = event.target.checked;
    togglecanRemoveAgent(id, isChecked)
      .then((response) => {
        setData(
          data.map((device) => {
            if (device.id === id) device.canRemoveAgent = isChecked;
            return device;
          })
        );
        setFeedback({
          severity: "success",
          message: `Agent ${
            isChecked ? "Install" : "Uninstall"
          } permission successfully modified.`,
          state: true
        });
      })
      .catch((error) =>
        setFeedback({
          severity: "error",
          message: MESSAGES.GENERIC_EXCEPTION_MESSAGE,
          state: true
        })
      );
  };

  const checkIsDeviceEnabled = (id) => {
    return data.find((device) => device.id === id)?.canRemoveAgent;
  };

  const headings = ["id", "name", "origin", "IsEnabled"];

  return (
    <div>
      <div className="main-title-heading-container">
        <PageTitle subheading="Manage Device Groups and Keep Your Devices Organized" />
        <div className="add">
          <CreateButton
            label={"Add Device Group"}
            onClickHandler={handleClickOpen}
            startIcon={<DevicesIcon />}
            requiredPrivileges={[PRIVILEGES.CREATE_DEVICE_GROUP.name]}
          />
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <SearchBar
          setSearchText={setSearchText}
          searchText={searchText}
          placeholder={"Search by Group Name"}
        />
      </div>
      <TableComponent
        headings={headings}
        data={data}
        actions={[
          {
            label: "Edit",
            onClickHandler: handleEdit,
            hasParameter: true,
            requiredPrivileges: [PRIVILEGES.EDIT_DEVICE_GROUP.name]
          },
          {
            label: "Delete",
            onClickHandler: handleDelete,
            hasParameter: true,
            requiredPrivileges: [PRIVILEGES.DELETE_DEVICE_GROUP.name]
          }
        ]}
        customColumnNames={{
          IsEnabled: "Uninstall Permission"
        }}
        options={{ hideIds: true }}
        styles={{
          table: { maxHeight: 480 },
          columns: {
            IsEnabled: { textAlign: "center" }
          }
        }}
        toggleAction={{
          toggleStatusChecker: checkIsDeviceEnabled,
          onChangeHandler: handleToggleStatus
        }}
      />
      {totalCount !== 0 ? (
        <div className="pagination">
          <Pagination
            totalCount={totalCount}
            page={pageNumber}
            setPage={setPageNumber}
            rowsPerPage={pageSize}
            setRowsPerPage={setPageSize}
          />
        </div>
      ) : null}
      <GroupForm
        open={open}
        setOpen={setOpen}
        setFeedback={setFeedback}
        selectedGroupId={selectedId}
        setFetchCount={setFetchCount}
        groupType={"DEVICE"}
      />
      <CustomDialog
        open={isDeleteConfirmationOpen}
        title="Confirm Deletion"
        content="Are you sure you want to delete?"
        setOpen={setIsDeleteConfirmationOpen}
        handleConfirm={handleConfirmDelete}
        dialogType={DIALOG_TYPES.CONFIRM}
      />
    </div>
  );
}

export default DeviceGroupList;
