import {
  CircularProgress,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  InputLabel,
  Button
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { getSmtpSettings, setSmtpSettings } from "../../API/customer";
import PageTitle from "../utility/PageTitle";
import CustomAlert from "../../Infobar";

function SmtpSettings({ setFeedback }) {
  const [loading, setLoading] = useState(false);

  const validate = Yup.object({
    host: Yup.string().required("Host is required"),
    port: Yup.number().required(),
    username: Yup.string().required("Required"),
    password: Yup.string().required("Required")
  });

  const user = useSelector(({ auth }) => auth.currentUser);

  function submitForm(values, resetForm) {
    if (!loading) {
      setLoading(true);
      setSmtpSettings(values)
        .then((response) => {
          setFeedback({
            message: response.data,
            severity: "success",
            state: true
          });
        })
        .catch((error) => {
          setFeedback({
            message: error.response.data,
            severity: "error",
            state: true
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }
  useEffect(() => {
    getSmtpSettings()
      .then((response) => {
        if (response.data) {
          formik.setValues(response.data);
        }
      })
      .catch((error) => {
        setFeedback({
          message: "Something went wrong",
          severity: "error",
          state: true
        });
      });
  }, []);

  const formik = useFormik({
    initialValues: {
      useSmtpConfig: false,
      host: "",
      port: "",
      username: "",
      password: ""
    },
    validationSchema: validate,
    onSubmit: (values, { resetForm }) => {
      submitForm(values, resetForm);
    }
  });

  return (
    <>
      <div className="form-container">
        <CustomAlert
          type="info"
          text="Define the SMTP server parameters to enable secure and reliable email notifications."
          styles={{ marginTop: "-4px" }}
        />
        <br></br>
        <form onSubmit={formik.handleSubmit}>
          <div className="form-input">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.useSmtpConfig}
                    onChange={(e) =>
                      formik.setFieldValue("useSmtpConfig", e.target.checked)
                    }
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Use SMTP Configuration?"
              />
            </FormGroup>
          </div>

          <div className="form-input">
            <InputLabel htmlFor="host" className="form-input-label" required>
              Host
            </InputLabel>
            <TextField
              id="host"
              placeholder=" Enter your host"
              name="host"
              className="form-textfield"
              size="small"
              fullWidth
              value={formik.values.host}
              onChange={formik.handleChange}
              error={formik.touched.host && Boolean(formik.errors.host)}
              helperText={formik.touched.host && formik.errors.host}
            />
          </div>
          <div className="form-input">
            <InputLabel htmlFor="port" className="form-input-label" required>
              Port
            </InputLabel>
            <TextField
              id="port"
              placeholder=" Enter your port"
              name="port"
              className="form-textfield"
              size="small"
              fullWidth
              value={formik.values.port}
              onChange={formik.handleChange}
              error={formik.touched.port && Boolean(formik.errors.port)}
              helperText={formik.touched.port && formik.errors.port}
            />
          </div>
          <div className="form-input">
            <InputLabel
              htmlFor="username"
              className="form-input-label"
              required
            >
              UserName
            </InputLabel>
            <TextField
              id="username"
              placeholder=" Enter your username"
              name="username"
              className="form-textfield"
              size="small"
              fullWidth
              value={formik.values.username}
              onChange={formik.handleChange}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
            />
          </div>
          <div className="form-input">
            <InputLabel
              htmlFor="password"
              className="form-input-label"
              required
            >
              Password
            </InputLabel>
            <TextField
              id="password"
              placeholder=" Enter your password"
              name="password"
              className="form-textfield"
              size="small"
              fullWidth
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </div>
          {loading ? (
            <div className="custom-float-right custom-loader">
              <CircularProgress size={18} color="inherit" />
              &nbsp; Testing configurations ..
            </div>
          ) : (
            <Button
              className="primary-button-filled"
              variant="contained"
              type="submit"
            >
              Save
            </Button>
          )}
        </form>
      </div>
    </>
  );
}

export default SmtpSettings;
