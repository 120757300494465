import Box from "@mui/material/Box";
import PageTitle from "../utility/PageTitle";
import { Outlet } from "react-router-dom";
import NavigationTabs from "../utility/NavigationTabs";
import { PRIVILEGES } from "../../utils/Privileges";

function UsbPolicy({ setFeedback, usbType }) {
  
  const tabList = [
    {
      label: usbType.title,
      path: usbType.path,
      requiredPrivileges: [PRIVILEGES.VIEW_USB.name]
    },
    {
      label: "Policy",
      path: `${usbType.path}/policy`,
      requiredPrivileges: [PRIVILEGES.VIEW_POLICY.name]
    }
  ];

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <PageTitle subheading="Block Unauthorized USB Devices and Whitelist Only Approved Ones with Product and Vendor IDs." />
        <br />
        <NavigationTabs tabList={tabList} />
        <Box sx={{ pt: 2 }}>
          <Outlet />
        </Box>
      </Box>
    </div>
  );
}

export default UsbPolicy;
