import React, { useEffect, useState } from "react";
import PageTitle from "../utility/PageTitle";
import MultiFilter from "../utility/MultiFilter";
import TableComponent from "../utility/Table";
import Pagination from "../utility/Pagination";
import { getPaginatedStatusReports } from "../../API/agentStatusReport";
import {
  capitalizefirstLetter,
  formatDateTimeString
} from "../../utils/Helpers";
import { AGENT_STATUS } from "../../utils/Constants";

export default function AgentStatusReport({ setFeedback }) {
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [filtersSelectedValue, setFiltersSelectedValue] = useState({});
  const [startTimeFilter, setStartTimeFilter] = useState();
  const [endTimeFilter, setEndTimeFilter] = useState();
  const [filterValuesList, setFilterValuesList] = useState({});

  function createAgentStatusFilterInFilterValuesList(agentStatuses) {
    const filterValuesList = {
      AgentStatus: []
    };

    for (const key in agentStatuses) {
      filterValuesList.AgentStatus.push({
        filterValue: agentStatuses[key],
        filterId: key
      });
    }
    filterValuesList.AgentStatus.push({
      filterValue: "All",
      filterId: "all"
    });
    return filterValuesList;
  }

  function getFilteredStatusReports() {
    const filters = {
      agentStatus:
        filtersSelectedValue.AgentStatus !== undefined &&
        filtersSelectedValue.AgentStatus !== "all"
          ? filtersSelectedValue.AgentStatus
          : "",
      searchText: searchText,
      pageSize: pageSize,
      pageNumber: pageNumber,
      startDttm: startTimeFilter,
      endDttm: endTimeFilter
    };

    getPaginatedStatusReports(filters)
      .then((response) => {
        const processedData = response.data.agentStatusReports.map((item) => {
          return {
            ...item,
            reportDttm: formatDateTimeString(item.reportDttm),
            agentStatus: capitalizefirstLetter(item.agentStatus)
          };
        });
        setData(processedData);
        setTotalCount(response.data.totalItems);
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: `Error in fetching agent status reports.`,
          state: true
        });
      });
  }

  useEffect(() => {
    setFilterValuesList(
      createAgentStatusFilterInFilterValuesList(AGENT_STATUS)
    );
  }, []);

  useEffect(() => {
    getFilteredStatusReports();
  }, [
    filtersSelectedValue,
    pageSize,
    searchText,
    pageNumber,
    startTimeFilter,
    endTimeFilter
  ]);


  const headings = [
    "deviceId",
    "deviceName",
    "reportDttm",
    "agentStatus",
    "action"
  ];

  return (
    <div>
      <div className="main-title-heading-container">
        <PageTitle subheading="Check the active, inactive, and uninstall states of the agent." />
      </div>

      <div
        style={{
          display: "flex",
          marginBottom: "20px",
          justifyContent: "space-between"
        }}
      >
        <MultiFilter
          filterValuesList={filterValuesList}
          filtersSelectedValue={filtersSelectedValue}
          setFiltersSelectedValue={setFiltersSelectedValue}
          showSearchBar={true}
          setSearchText={setSearchText}
          searchText={searchText}
          placeholder="Search Devices by ID"
          showTimeFilter={true}
          startTime={startTimeFilter}
          endTime={endTimeFilter}
          setStartTime={setStartTimeFilter}
          setEndTime={setEndTimeFilter}
        />
      </div>

      <TableComponent
        headings={headings}
        data={data}
        styles={{
          agentStatus: "chip"
        }}
        customColumnNames={{
          deviceName: "Device Name"
        }}
      />

      {totalCount !== 0 && (
        <div className="pagination">
          <Pagination
            totalCount={totalCount}
            page={pageNumber}
            setPage={setPageNumber}
            rowsPerPage={pageSize}
            setRowsPerPage={setPageSize}
          />
        </div>
      )}
    </div>
  );
}
