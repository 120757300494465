import { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CheckPermission } from "./CheckPermission";

export default function NavigationTabs({ tabList }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();  

  // Filter the tab list based on privileges
  const filteredTabList = tabList.filter((element) =>
    CheckPermission({ requiredPrivileges: element.requiredPrivileges })
  );
  
  // Ensure the user is navigated to the first available tab
  useEffect(() => {
    if (
      filteredTabList.length > 0 &&
      !filteredTabList.some((tab) => tab.path === pathname)
    ) {
      navigate(filteredTabList[0].path, { replace: true });
    }
  }, [filteredTabList, pathname, navigate]);

  const handleChange = (event, newValue) => {
    navigate(newValue);
  };

  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <Tabs
        value={pathname}
        onChange={handleChange}
        aria-label="navigation tabs"
      >
        {filteredTabList.map((element, index) => (
          <Tab
            key={index}
            label={element.label}
            to={element.path}
            value={element.path}
            component={Link}
          />
        ))}
      </Tabs>
    </Box>
  );
}
