import React, { useState, useEffect, useMemo } from "react";
import AssignFieldsDropdown from "../utility/AssignFieldsDropdown";
import { ACTION, RISK_LEVEL, usbTypeMap } from "../../utils/Constants";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addPolicy, fetchPolicy, updatePolicy } from "../../API/policy";
import {
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Divider,
  IconButton,
  Grid,
  Autocomplete
} from "@mui/material";
import { useLocation } from "react-router-dom";
import "./policy-form.css";
import {
  fetchAndFormatClassifications,
  fetchAndFormatFileCategories,
  fetchAndFormatUserGroups,
  setCurrentClassificationsForPolicy,
  setCurrentFileCategoriesForPolicy,
  setCurrentUserGroupsForPolicy,
  fetchAndFormatDeviceGroups,
  setCurrentDeviceGroupsForPolicy,
  fetchAndFormatSiteBlockingCategory,
  fetchAndFormatAppBlockingCategory,
  setCurrentSiteBlockingCategoryForPolicy,
  setCurrentAppBlockingCategoryForPolicy,
  fetchAndFormatUsbList,
  setCurrentWhitelistedUsbs
} from "../../utils/Helpers";
import FormShimmering from "../utility/FormShimmer";
import CloseIcon from "@mui/icons-material/Close";
import FormFooter from "../utility/FormFooter";
import CheckboxSelectorListWithSearch from "../utility/CheckBoxSelectorListWithSearch";
import CustomTooltip from "../../utils/Tooltip";

function PolicyForm({
  setFeedback,
  setOpen,
  open,
  selectedPolicyId,
  setFetchCount
}) {
  const [fileCategoryIds, setFileCategoryIds] = useState([]);
  const [classificationIds, setClassificationIds] = useState([]);
  const [userGroupIds, setUserGroupIds] = useState([]);
  const [deviceGroupIds, setDeviceGroupIds] = useState([]);
  const [whitelistedUsbIds, setWhiteListedUsbIds] = useState([]);
  const [appBlockingCategoryIds, setAppBlockingCategoryIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [usbs, setUsbs] = useState([]);
  const location = useLocation();
  const formType = selectedPolicyId ? "Update" : "Add";

  const destinationMap = useMemo(
    () => ({
      "/usb/policy": {
        id: 1,
        showAction: true,
        showWhitelistUsb: true,
        showDeviceGroup: true,
        type: usbTypeMap.usb.type,
        title: usbTypeMap.usb.title,
      },
      "/clipboard-policy": { id: 4 },
      "/screenshot-policy": { id: 5 },
      "/desktop-app/policy": {
        id: 6,
        showAppBlockingCategory: true,
        showDeviceGroup: true
      },
      "/ethernet-policy": { id: 7, showDeviceGroup: true },
      "/printer/policy": {
        id: 8,
        showAction: true,
        showWhitelistUsb: true,
        showDeviceGroup: true,
        type: usbTypeMap.printer.type,
        title: usbTypeMap.printer.title,
      }
    }),
    []
  );

  const currentPath = location.pathname;
  const destinationInfo = destinationMap[currentPath] || { id: 1 }; // Default to usb policy

  useEffect(() => {
    if (destinationInfo?.type) {
      fetchAndFormatUsbList(destinationInfo.type).then((data) => setUsbs(data));
    }
  }, [location.pathname]);

  useEffect(() => {
    setLoading(true);
    if (open) {
      if (formType === "Update" && selectedPolicyId) {
        fetchPolicy(selectedPolicyId)
          .then((response) => {
            const { id, name, risk, action } = response.data;
            formik.setValues({
              id,
              name,
              risk,
              action
            });
            setClassificationIds(response.data.classificationIds);
            setWhiteListedUsbIds(response.data.usbIds);
            setAppBlockingCategoryIds(response.data.appBlockingCategoryIds);
            setDeviceGroupIds(response.data.deviceGroupIds);
          })
          .catch((error) => {
            setFeedback({
              severity: "error",
              message: "An error occurred while fetching the policy.",
              state: true
            });
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setWhiteListedUsbIds([]);
        setAppBlockingCategoryIds([]);
        setDeviceGroupIds([]);
        formik.setValues(formik.initialValues);
        formik.setTouched({}, false);
        setLoading(false);
      }
    }
  }, [formType, selectedPolicyId, open]);

  const validate = Yup.object({
    name: Yup.string()
      .max(40, "Must be 40 characters or less")
      .required("Required")
  });

  const formik = useFormik({
    initialValues: {
      id: "",
      name: "",
      risk: RISK_LEVEL.HIGH,
      action: ACTION.BLOCK
    },
    validationSchema: validate,
    onSubmit: (values, { resetForm }) => {
      submitForm(values, resetForm);
    }
  });

  function submitForm(values, resetForm) {
    const payload = {
      ...values,
      fileCategoryIds,
      destinationIds: [destinationInfo.id],
      classificationIds,
      deviceGroupIds,
      appBlockingCategoryIds,
      usbIds: whitelistedUsbIds,
      userGroupIds
    };

    const promise =
      formType === "Add"
        ? addPolicy(payload)
        : updatePolicy(selectedPolicyId, payload);

    promise
      .then(() => {
        const message = formType === "Add" ? "added" : "updated";
        setFeedback({
          severity: "success",
          message: `Policy ${message} successfully`,
          state: true
        });
        setFetchCount((fetchCount) => fetchCount + 1);
        setOpen(false);
      })
      .catch(() => {
        setFeedback({
          severity: "error",
          message: "Something went wrong",
          state: true
        });
      });
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div
        className={`drawer-form-wrapper form-width ${
          open ? "drawer-form-open" : "drawer-form-closed"
        }`}
      >
        <Toolbar />
        <div className="close-button">
          <IconButton onClick={handleClose} sx={{ padding: 0 }}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="drawer-form-container">
          {loading ? (
            <FormShimmering />
          ) : (
            <form>
              <h5>{formType} Policy</h5>
              <Divider />
              <Grid
                container
                spacing={2}
                className="group-form-container"
                columns={24}
              >
                <Grid item md={12}>
                  <div className="form-input">
                    <InputLabel
                      htmlFor="policy-name"
                      className="form-input-label"
                      required
                    >
                      Policy Name
                      <CustomTooltip title="Enter a name for the policy. This will help you identify it later."></CustomTooltip>
                    </InputLabel>
                    <TextField
                      placeholder="Name"
                      size="small"
                      id="policy-name"
                      className="form-textfield"
                      fullWidth
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </div>

                  <div className="form-input">
                    <InputLabel
                      htmlFor="policy-action"
                      className="form-input-label"
                    >
                      Action
                      <CustomTooltip title="Select the action to take when the policy is triggered."></CustomTooltip>
                    </InputLabel>
                    <Select
                      displayEmpty
                      fullWidth
                      size="small"
                      className="form-selectfield"
                      labelId="policy-action"
                      name="action"
                      id="policy-action"
                      value={formik.values.action || ""}
                      onChange={formik.handleChange}
                      disabled={!destinationInfo.showAction}
                    >
                      <MenuItem value={ACTION.BLOCK}>Block</MenuItem>
                      <MenuItem value={ACTION.NOTIFY}>Notify</MenuItem>
                      <MenuItem value={ACTION.LOG}>Log</MenuItem>
                    </Select>
                  </div>

                  <div className="form-input">
                    <InputLabel
                      htmlFor="policy-risk"
                      className="form-input-label"
                    >
                      Risk
                      <CustomTooltip title="Select the risk level associated with this policy. This influences how critical the policy is."></CustomTooltip>
                    </InputLabel>
                    <Select
                      displayEmpty
                      fullWidth
                      size="small"
                      className="form-selectfield"
                      labelId="policy-risk"
                      name="risk"
                      id="policy-risk"
                      value={formik.values.risk}
                      onChange={formik.handleChange}
                    >
                      <MenuItem value={RISK_LEVEL.LOW}>Low</MenuItem>
                      <MenuItem value={RISK_LEVEL.MEDIUM}>Medium</MenuItem>
                      <MenuItem value={RISK_LEVEL.HIGH}>High</MenuItem>
                    </Select>
                  </div>

                  {destinationInfo.showWhitelistUsb && (
                    <div className="form-input">
                      <InputLabel className="form-input-label">
                        Select {destinationInfo.title} for whitelisting
                        <CustomTooltip title="Choose specific USB devices that are exempt from this policy."></CustomTooltip>
                      </InputLabel>
                      <Autocomplete
                        multiple
                        disableCloseOnSelect
                        size="small"
                        id="usbs"
                        options={usbs}
                        getOptionLabel={(option) => option.fieldValue}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={`Select ${destinationInfo.title} for Whitelisting`}
                          />
                        )}
                        value={usbs.filter((usb) => {
                          return whitelistedUsbIds.includes(usb.fieldId);
                        })}
                        onChange={(event, newValue) => {
                          setWhiteListedUsbIds(
                            newValue.map((usb) => usb.fieldId)
                          );
                        }}
                      />
                    </div>
                  )}

                  {destinationInfo.showAppBlockingCategory && (
                    <div className="form-input">
                      <InputLabel className="form-input-label">
                        App Blocking Category
                        <CustomTooltip title="Select the app categories to be restricted by this policy."></CustomTooltip>
                      </InputLabel>
                      <AssignFieldsDropdown
                        open={open}
                        setFieldIds={setAppBlockingCategoryIds}
                        fieldPlaceholder="App Blocking Category"
                        fetchAndFormatFields={fetchAndFormatAppBlockingCategory}
                        onSearch={false}
                        setCurrentFields={
                          setCurrentAppBlockingCategoryForPolicy
                        }
                        parentId={selectedPolicyId}
                        formType={formType}
                      />
                    </div>
                  )}
                </Grid>
                <Grid md={1} item sx={{ Height: "100%" }}>
                  <Divider orientation="vertical" sx={{ opacity: 1 }} />
                </Grid>
                <Grid item md={11}>
                  {destinationInfo.showDeviceGroup && (
                    <div className="form-input">
                      <div className="helper-text">
                        This policy will be applied to the below selected
                        group(s).
                      </div>
                      <br />
                      <CheckboxSelectorListWithSearch
                        heading="Select Device Groups"
                        setFeedback={setFeedback}
                        fetchAndFormatFields={fetchAndFormatDeviceGroups}
                        selectedRecords={deviceGroupIds}
                        setSelectedRecords={(values) => {
                          setDeviceGroupIds(values);
                        }}
                        options={{
                          showFilters: true,
                          showSearchBar: true
                        }}
                        maxHeight="300px"
                        showAddRecordButton={true}
                        addButtonUrl={"/device-groups"}
                        addButtonText={"Create Group"}
                      />
                    </div>
                  )}
                </Grid>
              </Grid>
              <FormFooter
                formType={formType}
                handleSubmit={formik.handleSubmit}
                handleClose={handleClose}
              />
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

export default PolicyForm;
