import React, { useEffect, useState } from "react";
import PageTitle from "../../utility/PageTitle";
import MultiFilter from "../../utility/MultiFilter";
import TableComponent from "../../utility/Table";
import Pagination from "../../utility/Pagination";
import DeviceDrawer from "./DeviceDrawer";
import { fetchAllGroupsByType } from "../../../API/group";
import { getDeviceActivity } from "../../../API/deviceActivity";
import { GROUP_TYPE } from "../../../utils/Constants";
import { formatSeconds } from "../../../utils/Helpers";

export default function DeviceMonitoring({ setFeedback }) {
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [filtersSelectedValue, setFiltersSelectedValue] = useState({});
  const [startTimeFilter, setStartTimeFilter] = useState();
  const [endTimeFilter, setEndTimeFilter] = useState();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [deviceGroups, setDeviceGroups] = useState([]);

  function fetchAllDeviceGroups() {
    fetchAllGroupsByType(GROUP_TYPE.DEVICE)
      .then((response) => {
        let devGroups = response.data.map((item) => ({
          filterValue: item.name,
          filterId: item.id
        }));
        setDeviceGroups(devGroups);
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "Error in fetching device groups.",
          state: true
        });
      });
  }

  useEffect(() => {
    fetchAllDeviceGroups();
  }, []);

  function getFilteredDeviceActivity() {
    const filters = {
      deviceGroupId:
        filtersSelectedValue.Groups !== undefined &&
        filtersSelectedValue.Groups !== "all"
          ? filtersSelectedValue.Groups
          : "",
      searchText: searchText,
      pageSize: pageSize,
      pageNumber: pageNumber,
      startDttm: startTimeFilter,
      endDttm: endTimeFilter
    };

    getDeviceActivity(filters)
      .then(({ data }) => {
        const devicesData = data.data.map((device) => ({
          ...device,
          totalActiveDuration: formatSeconds(device.totalActiveTimeInSeconds)
        }));
        setData(devicesData);
        setTotalCount(data.totalCount);
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "Error in fetching device list.",
          state: true
        });
      });
  }

  useEffect(() => {
    getFilteredDeviceActivity();
  }, [
    filtersSelectedValue,
    pageSize,
    searchText,
    pageNumber,
    startTimeFilter,
    endTimeFilter
  ]);

  const handleOpenDrawer = (deviceId) => {
    let device = data.find((device) => device.deviceId == deviceId);
    setSelectedDevice(device);
    setDrawerOpen(true);
  };

  const actions = [
    {
      label: "View details",
      onClickHandler: handleOpenDrawer,
      hasParameter: true
    }
  ];

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    setSelectedDevice(null);
  };

  const headings = [
    "deviceId",
    "deviceName",
    "displayName",
    "totalActiveDuration"
  ];

  return (
    <div>
      <div className="main-title-heading-container">
        <PageTitle subheading="Monitor device uptime to optimize performance." />
      </div>

      <div
        style={{
          display: "flex",
          marginBottom: "20px",
          justifyContent: "space-between"
        }}
      >
        <MultiFilter
          filterValuesList={{
            Groups: deviceGroups
          }}
          filtersSelectedValue={filtersSelectedValue}
          setFiltersSelectedValue={setFiltersSelectedValue}
          showSearchBar={true}
          setSearchText={setSearchText}
          searchText={searchText}
          placeholder="Search Devices"
          showTimeFilter={true}
          startTime={startTimeFilter}
          endTime={endTimeFilter}
          setStartTime={setStartTimeFilter}
          setEndTime={setEndTimeFilter}
        />
      </div>

      <TableComponent
        headings={headings}
        data={data}
        customColumnNames={{
          deviceName: "Device Name",
          displayName: "Display Name",
          totalActiveDuration: "Active Duration"
        }}
        options={{ hideIds: true }}
        actions={actions}
      />

      {totalCount !== 0 && (
        <div className="pagination">
          <Pagination
            totalCount={totalCount}
            page={pageNumber}
            setPage={setPageNumber}
            rowsPerPage={pageSize}
            setRowsPerPage={setPageSize}
          />
        </div>
      )}

      <DeviceDrawer
        open={drawerOpen}
        onClose={handleCloseDrawer}
        selectedDevice={selectedDevice}
        startDttm={startTimeFilter}
        endDttm={endTimeFilter}
        setFeedback={setFeedback}
      />
    </div>
  );
}
