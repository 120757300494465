import React from "react";
import Box from "@mui/material/Box";
import PageTitle from "../utility/PageTitle";
import { Outlet } from "react-router-dom";
import NavigationTabs from "../utility/NavigationTabs";
import { PRIVILEGES } from "../../utils/Privileges";

function SettingsPage({ setFeedback }) {
  const tabList = [
    {
      label: "Active Directory Configurations",
      path: "/settings/ad",
      requiredPrivileges: [PRIVILEGES.CONFIGURE_ACTIVE_DIRECTORY.name]
    },
    {
      label: "Password Configurations",
      path: "/settings/password-policy",
      requiredPrivileges: [PRIVILEGES.CONFIGURE_PASSWORD_POLICY.name]
    },
    {
      label: "SMTP Configurations",
      path: "/settings/smtp",
      requiredPrivileges: [PRIVILEGES.CONFIGURE_SMTP.name]
    }
  ];

  return (
    <>
      <PageTitle />
      <br />
      <Box sx={{ width: "100%" }}>
        <NavigationTabs tabList={tabList} />
        <Box sx={{ p: 2 }}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
}

export default SettingsPage;
