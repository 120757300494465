import Cookie from "universal-cookie";

const cookie = new Cookie();

class CookieService {
  get(key) {
    return cookie.get(key);
  }

  set(key, value, options) {
    cookie.set(key, value, options);
  }

  remove(key) {
    cookie.remove(key, { path: "/" });
  }

  removeAll() {
    const allCookies = cookie.getAll();
    Object.keys(allCookies).forEach((cookieName) => {      
      cookie.remove(cookieName);
    });
  }

  removeAllExcept(cookieSet) {
    const allCookies = cookie.getAll();

    Object.keys(allCookies).forEach((cookieName) => {
      if (!cookieSet.has(cookieName)) {
        cookie.remove(cookieName);
      }
    });
  }
}

export default new CookieService();
