import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Button, InputLabel, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getOrgName, updateOrgName } from "../../API/customer";
import CustomDialog from "../utility/Dialog";
import { DIALOG_TYPES } from "../../utils/Constants";
import CookieService from "../../services/CookieService";

export default function BasicCustomization({ setFeedback }) {
  const hostnameParts = window.location.host.split(".");
  const baseDomain = hostnameParts.slice(-2).join(".");

  const validationSchema = Yup.object({
    organization: Yup.string()
      .required("Organization Name is required")
      .max(48,"Must be 48 characters or less")
      .min(3,"Must be 3 characters or more")
      .matches(
        /^[a-zA-Z0-9-]+$/,
        "Organization Name can only contain alphabets, numbers, and '-'"
      )
      .matches(
        /^(?!www$|login$).*/i,
        "Organization Name not allowed."
      )
  });

  const formik = useFormik({
    initialValues: {
      organization: ""
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      updateOrgName(values.organization)
        .then(() => {
          setFeedback({
            severity: "success",
            message: "Organization updated successfully",
            state: true
          });
          CookieService.removeAll();
          window.location.href = updatedDomain;
        })
        .catch((error) =>
          setFeedback({
            severity: "error",
            message: error.response?.data || "Failed to update organization",
            state: true
          })
        );
    }
  });

  const updatedDomain = `${window.location.protocol}//${formik.values.organization}.${baseDomain}/`;

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  useEffect(() => {
    getOrgName()
      .then(({ data }) => {
        formik.setFieldValue("organization", data);
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: error.response?.data || "Failed to fetch organization name",
          state: true
        });
      });
  }, []);

  const handleConfirm = () => {
    setIsConfirmationOpen(false);
    formik.submitForm();
  };

  return (
    <Box className="form-container">
      <form onSubmit={formik.handleSubmit}>
        <div className="form-input">
          <InputLabel
            htmlFor="organization"
            className="form-input-label"
            required
          >
            Organization Name
          </InputLabel>
          <TextField
            size="small"
            id="organization"
            className="form-textfield"
            fullWidth
            name="organization"
            value={formik.values.organization}
            onChange={formik.handleChange}
            error={
              formik.touched.organization && Boolean(formik.errors.organization)
            }
            helperText={
              formik.touched.organization && formik.errors.organization
            }
          />
        </div>

        <div className="form-input">
          <InputLabel className="form-input-label">Dashboard URL</InputLabel>
          <TextField
            disabled
            size="small"
            className="form-textfield"
            fullWidth
            value={updatedDomain}
          />
        </div>

        <Button variant="contained" onClick={() => setIsConfirmationOpen(true)}>
          Save
        </Button>
      </form>

      <CustomDialog
        open={isConfirmationOpen}
        title="Confirm update"
        content="After updating organization, you will be logged out and redirected to the new login URL."
        setOpen={setIsConfirmationOpen}
        handleConfirm={handleConfirm}
        dialogType={DIALOG_TYPES.CONFIRM}
      />
    </Box>
  );
}
