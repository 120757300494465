import ClassificationList from "../components/data_classification/ClassificationList";
import PolicyList from "../components/policy/PolicyList";
import GroupList from "../components/groups/GroupList";
import DictionaryRules from "../components/rule/DictionaryRules";
import Dashboard from "../components/dashboard/Dashboard";
import UserList from "../components/end_user/UserList";
import DownloadModulePage from "../components/DownloadModulePage";
import DevicePage from "../components/device/DevicePage";
import AdForm from "../components/user_store/active_directory/AdForm";
import ReportingList from "../components/reporting/ReportingList";
import UserGroupList from "../components/groups/UserGroupList";
import DeviceGroupList from "../components/groups/DeviceGroupList";
import EmailReportList from "../components/reporting/EmailReportList";
import DeviceReportList from "../components/reporting/DeviceReportList";
import ApprovalRequestList from "../components/approval_requests/ApprovalRequests";
import AccountPage from "../components/AccountPage";
import SettingsPage from "../components/settings/SettingsPage";
import SmtpSettings from "../components/configurations/SmtpSettings";
import EmailTemplateEditorPage from "../components/email_templates/EmailTemplateEditorPage";
import HttpPolicy from "../components/policy/HttpPolicy";
import UsbPolicy from "../components/policy/UsbPolicy";
import PolicyBreachAlertList from "../components/alerts/PolicyBreachAlertList";
import UsbReportList from "../components/reporting_dashboard/UsbReporting";
import HttpReportList from "../components/reporting_dashboard/HttpReporting";
import DesktopAppReportList from "../components/reporting_dashboard/DesktopAppReporting";
import EthernetReportList from "../components/reporting_dashboard/EthernetReporting";
import DesktopAppPolicy from "../components/policy/DesktopAppPolicy";
import OrganizationalDomains from "../components/organizational_domains/OrganizationalDomainList";
import ManageRolesAndUsers from "../components/roles_and_admins/ManageRolesAndUsers";
import BlockUsb from "../components/UsbBlocking/BlockUsbList";
import EmailTemplatesList from "../components/email_templates/EmailTemplatesList";
import BlockingCategories from "../components/policy/http_policy/domain_categories/BlockUrlList";
import OrganizationalApps from "../components/application_blocking/ApplicationList";
import AppBlockingCategories from "../components/application_blocking/AppCategoryList";
import RoleList from "../components/roles_and_admins/RoleList";
import DashboardUserList from "../components/roles_and_admins/UserList";
import PasswordConfiguration from "../components/password_configuration/Configuration";
import BulkReport from "../components/bulk_upload/BulkReport";
import AgentStatusReport from "../components/reporting_dashboard/AgentStatusReport";
import DeviceMonitoring from "../components/reporting_dashboard/device_monitoring/DeviceMonitoring.js";
import { Navigate } from "react-router-dom";
import { ADMIN_PRIVILEGE, PRIVILEGES } from "./Privileges";
import Customization from "../components/customization/Customization.js";
import BasicCustomization from "../components/customization/Basic.js";
import PrinterReportList from "../components/reporting_dashboard/PrinterReporting.js";
import { usbTypeMap } from "./Constants.js";

const ROUTES = [
  { path: "/", element: Dashboard },
  { path: "/my-account", element: AccountPage },
  {
    path: "/view-users",
    element: UserList,
    requiredPrivileges: [PRIVILEGES.VIEW_USER_LIST.name]
  },
  {
    path: "/view-devices",
    element: DevicePage,
    requiredPrivileges: [PRIVILEGES.VIEW_DEVICE_LIST.name]
  },
  {
    path: "/classification-list",
    element: ClassificationList,
    requiredPrivileges: [PRIVILEGES.VIEW_CLASSIFICATION.name]
  },
  {
    path: "/rule-list",
    element: DictionaryRules,
    requiredPrivileges: [PRIVILEGES.VIEW_RULE.name]
  },
  {
    path: "/user-groups",
    element: UserGroupList,
    requiredPrivileges: [PRIVILEGES.VIEW_USER_GROUPS.name]
  },
  {
    path: "/device-groups",
    element: DeviceGroupList,
    requiredPrivileges: [PRIVILEGES.VIEW_DEVICE_GROUPS.name]
  },
  { path: "/dashboard", element: Dashboard },
  {
    path: "/usb",
    element: UsbPolicy,
    props:{usbType:usbTypeMap.usb},
    requiredPrivileges: [PRIVILEGES.VIEW_POLICY.name, PRIVILEGES.VIEW_USB.name],
    children: [
      {
        path: "",
        element: BlockUsb,
        props:{usbType:usbTypeMap.usb},
        requiredPrivileges: [PRIVILEGES.VIEW_USB.name]
      },
      {
        path: "policy",
        element: PolicyList,
        requiredPrivileges: [PRIVILEGES.VIEW_POLICY.name]
      }
    ]
  },
  {
    path: "/printer",
    element: UsbPolicy,
    props:{usbType:usbTypeMap.printer},
    requiredPrivileges: [PRIVILEGES.VIEW_POLICY.name, PRIVILEGES.VIEW_USB.name],
    children: [
      {
        path: "",
        element: BlockUsb,
        props:{usbType:usbTypeMap.printer},
        requiredPrivileges: [PRIVILEGES.VIEW_USB.name]
      },
      {
        path: "policy",
        element: PolicyList,
        requiredPrivileges: [PRIVILEGES.VIEW_POLICY.name]
      }
    ]
  },
  {
    path: "/ethernet-policy",
    element: PolicyList,
    requiredPrivileges: [PRIVILEGES.VIEW_POLICY.name]
  },
  {
    path: "/email-policy",
    element: PolicyList,
    requiredPrivileges: [PRIVILEGES.VIEW_POLICY.name]
  },
  {
    path: "/http",
    element: HttpPolicy,
    requiredPrivileges: [
      PRIVILEGES.VIEW_POLICY.name,
      PRIVILEGES.VIEW_DOMAIN_AND_CATEGORY.name
    ],
    children: [
      {
        path: "",
        element: BlockingCategories,
        requiredPrivileges: [PRIVILEGES.VIEW_DOMAIN_AND_CATEGORY.name]
      },
      {
        path: "policy",
        element: PolicyList,
        requiredPrivileges: [PRIVILEGES.VIEW_POLICY.name]
      }
    ]
  },
  {
    path: "/desktop-app",
    element: DesktopAppPolicy,
    requiredPrivileges: [
      PRIVILEGES.VIEW_POLICY.name,
      PRIVILEGES.VIEW_APP.name,
      PRIVILEGES.VIEW_APP_CATEGORY.name
    ],
    children: [
      {
        path: "",
        element: OrganizationalApps,
        requiredPrivileges: [PRIVILEGES.VIEW_APP.name]
      },
      {
        path: "category",
        element: AppBlockingCategories,
        requiredPrivileges: [PRIVILEGES.VIEW_APP_CATEGORY.name]
      },
      {
        path: "policy",
        element: PolicyList,
        requiredPrivileges: [PRIVILEGES.VIEW_POLICY.name]
      }
    ]
  },
  {
    path: "/reporting-list",
    element: ReportingList,
    requiredPrivileges: [PRIVILEGES.VIEW_REPORTS.name]
  },
  {
    path: "/reporting/email-reports",
    element: EmailReportList,
    requiredPrivileges: [PRIVILEGES.VIEW_REPORTS.name]
  },
  {
    path: "/device-reports",
    element: DeviceReportList,
    requiredPrivileges: [PRIVILEGES.VIEW_REPORTS.name]
  },
  {
    path: "/approval-requests",
    element: ApprovalRequestList,
    requiredPrivileges: [PRIVILEGES.VIEW_APPROVAL.name]
  },
  {
    path: "/organizational-domains",
    element: OrganizationalDomains,
    requiredPrivileges: [PRIVILEGES.VIEW_ORGANIZATIONAL_DOMAIN.name]
  },
  {
    path: "/settings",
    element: SettingsPage,
    requiredPrivileges: [
      PRIVILEGES.CONFIGURE_ACTIVE_DIRECTORY.name,
      PRIVILEGES.CONFIGURE_PASSWORD_POLICY.name,
      PRIVILEGES.CONFIGURE_SMTP.name
    ],
    children: [
      {
        path: "",
        element: () => <Navigate to="ad" />,
        requiredPrivileges: [PRIVILEGES.CONFIGURE_ACTIVE_DIRECTORY.name]
      },
      {
        path: "ad",
        element: AdForm,
        requiredPrivileges: [PRIVILEGES.CONFIGURE_ACTIVE_DIRECTORY.name]
      },
      {
        path: "smtp",
        element: SmtpSettings,
        requiredPrivileges: [PRIVILEGES.CONFIGURE_SMTP.name]
      },
      {
        path: "password-policy",
        element: PasswordConfiguration,
        requiredPrivileges: [PRIVILEGES.CONFIGURE_PASSWORD_POLICY.name]
      }
    ]
  },
  {
    path: "/customization/email-templates/edit",
    element: EmailTemplateEditorPage,
    requiredPrivileges: [PRIVILEGES.CONFIGURE_EMAIL_TEMPLATE.name]
  },
  {
    path: "/policy-breach-alert",
    element: PolicyBreachAlertList,
    requiredPrivileges: [PRIVILEGES.VIEW_NOTIFICATION.name]
  },
  {
    path: "/manage",
    element: ManageRolesAndUsers,
    children: [
      {
        path: "",
        element: () => <Navigate to="role" />
      },
      {
        path: "role",
        element: RoleList
      },
      {
        path: "user",
        element: DashboardUserList
      }
    ]
  },
  {
    path: "/customization",
    element: Customization,
    requiredPrivileges: [
      ADMIN_PRIVILEGE,
      PRIVILEGES.CONFIGURE_EMAIL_TEMPLATE.name
    ],
    children: [
      {
        path: "basic",
        element: BasicCustomization
      },
      {
        path: "email-templates",
        element: EmailTemplatesList,
        requiredPrivileges: [PRIVILEGES.CONFIGURE_EMAIL_TEMPLATE.name]
      }
    ]
  },
  {
    path: "/reporting/bulk-uploads",
    element: BulkReport,
    requiredPrivileges: [PRIVILEGES.VIEW_REPORTS.name]
  },
  {
    path: "/reporting/usb-reporting",
    element: UsbReportList,
    requiredPrivileges: [PRIVILEGES.VIEW_REPORTS.name]
  },
  {
    path: "/reporting/printer-reporting",
    element: PrinterReportList,
    requiredPrivileges: [PRIVILEGES.VIEW_REPORTS.name]
  },
  {
    path: "/reporting/http-reporting",
    element: HttpReportList,
    requiredPrivileges: [PRIVILEGES.VIEW_REPORTS.name]
  },
  {
    path: "/reporting/desktop-apps-reporting",
    element: DesktopAppReportList,
    requiredPrivileges: [PRIVILEGES.VIEW_REPORTS.name]
  },
  {
    path: "/reporting/ethernet-reporting",
    element: EthernetReportList,
    requiredPrivileges: [PRIVILEGES.VIEW_REPORTS.name]
  },
  {
    path: "/reporting/device-monitoring",
    element: DeviceMonitoring,
    requiredPrivileges: [PRIVILEGES.VIEW_REPORTS.name]
  },
  {
    path: "/reporting/agent-status-report",
    element: AgentStatusReport,
    requiredPrivileges: [PRIVILEGES.VIEW_REPORTS.name]
  }
];

export { ROUTES };
