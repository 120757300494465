import NoRecordsFound from "../assets/Illustrator/NoRecordsFound";
import { CheckPermission } from "./CheckPermission";

export default function CheckPrivilege({
  requiredPrivileges,
  requiredAll = false,
  fallback = null,
  children
}) {
  const hasPermission = CheckPermission({ requiredPrivileges, requiredAll });
  return hasPermission ? children : fallback;
}
