import { Button } from "@mui/material";
import CheckPrivilege from "./CheckPrivilege";

export default function CreateButton({
  onClickHandler,
  startIcon,
  label,
  requiredPrivileges,
  styles
}) {
  return (
    <CheckPrivilege requiredPrivileges={requiredPrivileges}>
      <Button
        className="btn-primary-main-add"
        onClick={onClickHandler}
        startIcon={startIcon}
        sx={styles}
      >
        {label}
      </Button>
    </CheckPrivilege>
  );
}
