import { properties } from "../properties";
import api from "../services/api";

function createCustomer(values) {
  return api.post(`${properties.adminService}/customer/create`, values);
}

function getSmtpSettings() {
  return api.get(`${properties.adminService}/customer/smtp-settings`);
}

function setSmtpSettings(smtpSettings) {
  return api.patch(
    `${properties.adminService}/customer/smtp-settings`,
    smtpSettings
  );
}

function setOrganizationalDomains(data) {
  return api.patch(
    `${properties.adminService}/customer/organizational-domains`,
    data
  );
}

function getOrganizationalDomains() {
  return api.get(`${properties.adminService}/customer/organizational-domains`);
}

function createPasswordResetLink(email) {
  return api.post(`${properties.adminService}/customer/password-reset-link`, {
    email
  });
}

function changePassword(data) {
  return api.put(`${properties.adminService}/customer/password`, data);
}

function getOrgName() {
  return api.get(`${properties.adminService}/customer/organization`);
}

function updateOrgName(data) {
  return api.patch(`${properties.adminService}/customer/organization`, data, {
    headers: {
      "Content-Type": "text/plain"
    }
  });
}

export {
  createCustomer,
  getSmtpSettings,
  setSmtpSettings,
  setOrganizationalDomains,
  getOrganizationalDomains,
  createPasswordResetLink,
  changePassword,
  getOrgName,
  updateOrgName
};
