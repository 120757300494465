import { useEffect, useState } from "react";
import TableComponent from "../utility/Table";
import { fetchReports } from "../../API/bulkUpload";
import PageTitle from "../utility/PageTitle";
import { formatDateTimeString } from "../../utils/Helpers";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import PendingIcon from "@mui/icons-material/Pending";
import { JOB_HEADINGS } from "../../utils/Constants";
import Filter from "../utility/Filter";

export default function BulkReport({ setFeedBack }) {
  const [data, setData] = useState([]);
  const [selectedJob, setSelectedJob] = useState("");

  const chipsStyles = {
    jobStatus: {
      STARTED: {
        icon: <PendingIcon style={{ color: "#7B341E", fontSize: "medium" }} />,
        style: {
          color: "#7B341E",
          backgroundColor: "#FEEBC8",
          borderRadius: "5px",
          fontWeight: "bold"
        }
      },
      COMPLETED: {
        icon: (
          <CheckCircleIcon style={{ color: "#15803D", fontSize: "medium" }} />
        ),
        style: {
          color: "#15803D",
          backgroundColor: "#DCFCE7",
          borderRadius: "5px",
          fontWeight: "bold"
        }
      },
      FAILED: {
        icon: <CancelIcon style={{ color: "#B91C1C", fontSize: "medium" }} />,
        style: {
          color: "#B91C1C",
          backgroundColor: "#FEE2DC",
          borderRadius: "5px",
          fontWeight: "bold"
        }
      }
    }
  };

  useEffect(() => {
    fetchReports(selectedJob)
      .then(({ data }) => {
        const processedData = data.map((item) => {
          return {
            ...item,
            jobName: JOB_HEADINGS[item.jobName],
            startTime: formatDateTimeString(item.startTime),
            endTime: item.endTime ? formatDateTimeString(item.endTime) : "--"
          };
        });
        setData(processedData);
      })
      .catch(() => {
        setFeedBack({
          severity: "error",
          message: "There is an issue fetching bulk reports.",
          state: true
        });
      });
  }, [selectedJob]);

  const jobFilter = () => {
    return Object.entries(JOB_HEADINGS).map(([filterId, filterValue]) => ({
      filterId,
      filterValue
    }));
  };

  return (
    <>
      <div className="main-title-heading-container">
        <PageTitle subheading="Detailed overview of all performed Bulk Operations." />
      </div>
      <div style={{ display: "flex" }}>
        <Filter
          filterType={"job name"}
          filterValue={selectedJob}
          setFilterValue={setSelectedJob}
          filters={jobFilter()}
          styles={{ minWidth: 180 }}
        />
      </div>
      <TableComponent
        headings={[
          "jobName",
          "startTime",
          "endTime",
          "exitMessage",
          "jobStatus"
        ]}
        data={data}
        styles={{ jobStatus: "chip" }}
        chipsStyles={chipsStyles}
        customColumnNames={{
          jobStatus: "Status"
        }}
      />
    </>
  );
}
