import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Toolbar,
  IconButton,
  Stack,
  Divider,
  InputLabel,
  TextField,
  MenuItem,
  Select,
  Autocomplete
} from "@mui/material";
import { useEffect } from "react";
import {
  createAlert,
  fetchAlert,
  updateAlert
} from "../../API/policyBreachAlert";
import FormShimmering from "../utility/FormShimmer";
import CloseIcon from "@mui/icons-material/Close";
import BeenhereOutlinedIcon from "@mui/icons-material/BeenhereOutlined";
import AddIcon from "@mui/icons-material/Add";
import { fetchAllDestinations } from "../../API/destination";
import { fetchAllPoliciesByDestination } from "../../API/policy";
import CustomTooltip from "../../utils/Tooltip";
import { Fade } from "@mui/material";

function PolicyBreachAlertForm({
  setFeedback,
  setOpen,
  open,
  selectedAlertId,
  setFetchCount
}) {
  let formType = "Add";

  if (selectedAlertId !== "") formType = "Update";
  const [loading, setLoading] = useState(true);
  const [selectedPolicyIds, setSelectedPolicyIds] = useState([]);
  const [destinationList, setDestinationList] = useState([]);
  const [policyListForDestination, setPolicyListForDestination] = useState([]);
  const [destinationId, setDestinationId] = useState("");

  useEffect(() => {
    if (destinationId) {
      fetchAllPoliciesByDestination(destinationId)
        .then((response) => {
          if (response.data)
            setPolicyListForDestination(response.data.policies);
        })
        .catch((error) => {
          setFeedback({
            severity: "error",
            message: "Something went wrong!",
            state: true
          });
        });
    }
  }, [destinationId]);

  useEffect(() => {
    setLoading(true);
    if (open) {
      fetchAllDestinations()
        .then(({ data }) => {
          setDestinationList(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (formType === "Update") {
      fetchAlert(selectedAlertId)
        .then(({ data }) => {
          formik.setValues(data);
          setSelectedPolicyIds(data.policyIds);
          setDestinationId(data.destinationId);
        })
        .catch((error) => console.log(error.response))
        .finally(() => {
          setLoading(false);
        });
    } else {
      formik.setValues(formik.initialValues);
      setSelectedPolicyIds([]);
      setLoading(false);
    }
  }, [selectedAlertId, open]);

  const validate = Yup.object({
    name: Yup.string()
      .max(20, "Must be 20 characters or less")
      .required("Required"),
    description: Yup.string().max(300, "Must be 300 characters or less"),
    emailList: Yup.array().of(Yup.string().email("Invalid email address"))
  });

  const formik = useFormik({
    initialValues: {
      id: "",
      name: "",
      description: "",
      destinationId: "",
      emailList: []
    },
    validationSchema: validate,
    onSubmit: (values, { resetForm }) => {
      submitForm(values, resetForm);
    }
  });

  function submitForm(values, resetForm) {
    let promise;
    if (formType === "Add")
      promise = createAlert({
        ...values,
        policyIds: selectedPolicyIds
      });
    else {
      promise = updateAlert(selectedAlertId, {
        ...values,
        policyIds: selectedPolicyIds
      });
    }

    promise
      .then((response) => {
        const message = formType === "Add" ? "created" : "updated";
        setFeedback({
          severity: "success",
          message: `Notification ${message} Successfully`,
          state: true
        });
        setFetchCount((fetchCount) => fetchCount + 1);
        setOpen(false);
      })
      .catch(function (error) {
        console.log(error);
        setFeedback({
          severity: "error",
          message: "Something went wrong!",
          state: true
        });
      });
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleDestinationChange = (event) => {
    formik.setFieldValue("destinationId", event.target.value);
    setDestinationId(event.target.value);
    setSelectedPolicyIds([]);
  };

  return (
    <div
      className={`drawer-form-wrapper ${
        open ? "drawer-form-open" : "drawer-form-closed"
      }`}
    >
      <Toolbar />
      <br />
      <div className="close-button">
        <IconButton onClick={handleClose} sx={{ padding: 0 }}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className="drawer-form-container">
        {loading ? (
          <FormShimmering />
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <h5>{formType} Notification</h5>
            <Divider />
            <div className="form-input">
              <InputLabel htmlFor="name" className="form-input-label" required>
                Notification Name
                <CustomTooltip title="Enter a unique name to easily identify this alert."></CustomTooltip>
              </InputLabel>
              <TextField
                size="small"
                id="name"
                placeholder="Example"
                className="form-textfield"
                fullWidth
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </div>
            <div className="form-input">
              <InputLabel htmlFor="description" className="form-input-label">
                Description
                <CustomTooltip title="Add a brief description to explain the alert's purpose."></CustomTooltip>
              </InputLabel>
              <TextField
                multiline
                rows={2}
                size="small"
                id="description"
                placeholder="Write a short description"
                className="form-textfield"
                fullWidth
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />
            </div>
            <div className="form-input">
              <InputLabel htmlFor="destination" className="form-input-label">
                Select Destination
                <CustomTooltip title="Choose a destination to filter relevant policies for this alert."></CustomTooltip>
              </InputLabel>
              <Select
                displayEmpty
                fullWidth
                size="small"
                className="form-selectfield"
                labelId="destination"
                name="destinationId"
                id="destination"
                onChange={(event) => handleDestinationChange(event)}
                value={formik.values.destinationId}
              >
                <MenuItem value="" key="" disabled hidden>
                  <span className="placeholder-style">Select Destination</span>
                </MenuItem>
                {destinationList.map((destination, index) => (
                  <MenuItem value={destination.id} key={destination.id}>
                    {destination.name}
                  </MenuItem>
                ))}
                <CustomTooltip title="Choose a destination to filter relevant policies for this alert."></CustomTooltip>
              </Select>
            </div>
            <div className="form-input">
              <InputLabel htmlFor="policies" className="form-input-label">
                Select Policies
                <CustomTooltip title="Select policies that will trigger this alert when breached."></CustomTooltip>
              </InputLabel>
              <Autocomplete
                multiple
                disableCloseOnSelect
                size="small"
                id="policies"
                options={policyListForDestination}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Policies" />
                )}
                value={policyListForDestination.filter((policy) => {
                  return selectedPolicyIds.includes(policy.id);
                })}
                onChange={(event, newValue) => {
                  setSelectedPolicyIds(newValue.map((policy) => policy.id));
                }}
                noOptionsText={
                  destinationId !== ""
                    ? "No options"
                    : "Please select destination"
                }
              />
            </div>
            <div className="form-input">
              <InputLabel htmlFor="email-list" className="form-input-label">
                Recipients{" "}
                <span className="helper-text">
                  (Press Enter after each email to select multiple recipients)
                </span>
              </InputLabel>
              <Autocomplete
                autoSelect
                multiple
                size="small"
                id="email-list"
                freeSolo
                options={[]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Enter Email"
                    error={
                      formik.touched.emailList &&
                      Boolean(formik.errors.emailList)
                    }
                    helperText={
                      formik.touched.emailList && formik.errors.emailList
                    }
                  />
                )}
                value={formik.values.emailList}
                onChange={(event, newValue) => {
                  formik.setFieldValue("emailList", newValue);
                }}
              />
            </div>
            <br />
            <Stack spacing={2} direction="row" justifyContent="flex-end">
              <Button
                className="primary-button-outlined"
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                className="primary-button-filled"
                variant="contained"
                type="submit"
                startIcon={
                  formType == "Update" ? <BeenhereOutlinedIcon /> : <AddIcon />
                }
              >
                {formType}
              </Button>
            </Stack>
          </form>
        )}
      </div>
    </div>
  );
}

export default PolicyBreachAlertForm;
