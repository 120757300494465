import { properties } from "../properties";
import api from "../services/api";

function addUser(values) {
  return api.post(`${properties.adminService}/dashboard-user`, values);
}

function fetchUser(id) {
  return api.get(`${properties.adminService}/dashboard-user/${id}`);
}

function updateUser(id, values) {
  return api.put(`${properties.adminService}/dashboard-user/${id}`, values);
}

function getAllUsersWithFilter(pageSize, pageNumber, searchText) {
  let url = `${properties.adminService}/dashboard-user/all?`;
  if (pageSize && pageNumber != undefined) {
    url += `pageSize=${pageSize}&pageNumber=${pageNumber}`;
  }
  if (searchText) {
    url += `&searchText=${searchText}`;
  }
  return api.get(url);
}

function deleteUser(id) {
  return api.delete(`${properties.adminService}/dashboard-user/${id}`);
}

export {
  addUser,
  fetchUser,
  updateUser,
  deleteUser,
  getAllUsersWithFilter
};
