import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Toolbar,
  IconButton,
  Stack,
  Divider,
  InputLabel,
  TextField,
  Chip,
  Checkbox
} from "@mui/material";
import { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import BeenhereOutlinedIcon from "@mui/icons-material/BeenhereOutlined";
import AddIcon from "@mui/icons-material/Add";
import {
  createOrganizationDomain,
  updateOrganizationDomain
} from "../../API/organizationalDomains";
import { getIconComponentByName } from "./OrganizationalDomainIcons";
import InputList from "../utility/InputList";
import CustomAlert from "../../Infobar";

function OrganizationalDomainForm({
  setFeedback,
  setOpen,
  open,
  selectedOrganizationDomain,
  setFetchCount,
  isDefault
}) {
  let formType =
    selectedOrganizationDomain && selectedOrganizationDomain.id
      ? "Update"
      : "Add";

  const [domainList, setDomainList] = useState([]);

  const IconComponent = getIconComponentByName(
    selectedOrganizationDomain?.name
  );

  useEffect(() => {
    if (formType === "Update") {
      formik.setFieldValue("name", selectedOrganizationDomain.name);
      formik.setFieldValue("url", selectedOrganizationDomain.url);
      formik.setFieldValue("id", selectedOrganizationDomain.id);
      formik.setFieldValue(
        "matchExactUrl",
        selectedOrganizationDomain.matchExactUrl
      );
      formik.setFieldValue(
        "checkDomainInUrl",
        selectedOrganizationDomain.checkDomainInUrl
      );
      setDomainList(selectedOrganizationDomain.domains);
    } else if (isDefault) {
      formik.setFieldValue("name", selectedOrganizationDomain.name);
      formik.setFieldValue("url", selectedOrganizationDomain.url);
      formik.setFieldValue(
        "matchExactUrl",
        selectedOrganizationDomain.matchExactUrl
      );
      formik.setFieldValue(
        "checkDomainInUrl",
        selectedOrganizationDomain.checkDomainInUrl
      );
      setDomainList([]);
    } else {
      formik.setValues(formik.initialValues);
      setDomainList([]);
    }
  }, [selectedOrganizationDomain, open]);

  const validate = Yup.object({
    name: Yup.string()
      .max(20, "Must be 20 characters or less")
      .required("Required"),
    url: Yup.string().required("Required")
  });

  const formik = useFormik({
    initialValues: {
      id: "",
      name: "",
      url: "",
      matchExactUrl: false,
      checkDomainInUrl: false
    },
    validationSchema: validate,
    onSubmit: (values, { resetForm }) => {
      submitForm(values, resetForm);
    }
  });

  function submitForm(values, resetForm) {
    let promise;
    if (formType === "Add")
      promise = createOrganizationDomain({
        ...values,
        domains: domainList
      });
    else {
      promise = updateOrganizationDomain(selectedOrganizationDomain.id, {
        ...values,
        domains: domainList
      });
    }

    promise
      .then((response) => {
        const message = formType === "Add" ? "added" : "updated";
        setFeedback({
          severity: "success",
          message: `Organizational Domain ${message}  Successfully`,
          state: true
        });
        setFetchCount((fetchCount) => fetchCount + 1);
        setOpen(false);
      })
      .catch(function (error) {
        setFeedback({
          severity: "error",
          message: error.response.data,
          state: true
        });
      });
  }

  const handleClose = () => {
    setOpen(false);
  };
  const handleAddElement = (list, setList) => {
    setList([...list, ""]);
  };

  const handleDeleteElement = (ind, list, setList) => {
    const updatedList = [...list];
    updatedList.splice(ind, 1);
    setList(updatedList);
  };
  return (
    <div
      className={`drawer-form-wrapper ${
        open ? "drawer-form-open" : "drawer-form-closed"
      }`}
    >
      <Toolbar />
      <br />
      <div className="close-button">
        <IconButton onClick={handleClose} sx={{ padding: 0 }}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className="drawer-form-container">
        <form onSubmit={formik.handleSubmit}>
          <h5>{formType} Organizational Domain</h5>
          <Divider />
          {isDefault ? (
            <div className="summary-head margin-head">
              <br />
              <IconComponent />
              &emsp;
              <div className="summary-text">
                <span className="summary-head">
                  {selectedOrganizationDomain.name} &nbsp;
                  {isDefault ? (
                    <Chip
                      label="Default"
                      color="primary"
                      sx={{ fontSize: "0.7125rem", height: "20px" }}
                      variant="outlined"
                    />
                  ) : null}
                </span>
              </div>
              <br />
            </div>
          ) : (
            <>
              <CustomAlert
                type="info"
                text="Specify Domain and URL to enhance your organizational security."
                styles={{ marginTop: "15px" }}
              />
              <div className="form-input">
                <InputLabel
                  htmlFor="name"
                  className="form-input-label"
                  required
                >
                  Name
                </InputLabel>
                <TextField
                  size="small"
                  id="name"
                  placeholder="e.g. AWS"
                  className="form-textfield"
                  fullWidth
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </div>
              <div className="form-input">
                <InputLabel htmlFor="url" className="form-input-label" required>
                  URL
                </InputLabel>
                <TextField
                  size="small"
                  id="url"
                  placeholder="e.g. https://signin.aws.amazon.com/signin"
                  className="form-textfield"
                  fullWidth
                  name="url"
                  value={formik.values.url}
                  onChange={formik.handleChange}
                  error={formik.touched.url && Boolean(formik.errors.url)}
                  helperText={formik.touched.url && formik.errors.url}
                />
              </div>
              <div className="checkbox-field">
                <Checkbox
                  name="matchExactUrl"
                  id="matchExactUrl"
                  checked={formik.values.matchExactUrl}
                  onChange={(e) =>
                    formik.setFieldValue("matchExactUrl", e.target.checked)
                  }
                />
                &emsp;
                <InputLabel htmlFor="matchExactUrl">Match Exact URL</InputLabel>
                &emsp;&emsp;
                <Checkbox
                  name="checkDomainInUrl"
                  id="checkDomainInUrl"
                  checked={formik.values.checkDomainInUrl}
                  onChange={(e) =>
                    formik.setFieldValue("checkDomainInUrl", e.target.checked)
                  }
                />
                &emsp;
                <InputLabel htmlFor="checkDomainInUrl">
                  Check Domain In URL
                </InputLabel>
              </div>
              <br />
            </>
          )}
          {domainList.length > 0 ? <h6>Allowed Domains</h6> : null}
          <InputList
            list={domainList}
            setList={setDomainList}
            placeholder="Enter Domain"
            buttonText="Add Domains"
          />
          <br />
          <Stack spacing={2} direction="row" justifyContent="flex-end">
            <Button
              className="primary-button-outlined"
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              className="primary-button-filled"
              variant="contained"
              type="submit"
              startIcon={
                formType == "Update" ? <BeenhereOutlinedIcon /> : <AddIcon />
              }
            >
              {formType}
            </Button>
          </Stack>
        </form>
      </div>
    </div>
  );
}

export default OrganizationalDomainForm;
