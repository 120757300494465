import { properties } from "../properties";
import api from "../services/api";

function addUser(values) {
  return api.post(`${properties.adminService}/email-user`, values);
}

function fetchUser(id) {
  return api.get(`${properties.adminService}/email-user/${id}`);
}

function updateUser(id, values) {
  return api.put(`${properties.adminService}/email-user/${id}`, values);
}

function getAllUsersWithFilter(
  isDeviceLinked,
  pageSize,
  pageNumber,
  searchText
) {
  return api.get(
    `${properties.adminService}/email-user${
      isDeviceLinked == undefined
        ? ""
        : `?isDeviceLinked=${isDeviceLinked}&pageSize=${pageSize}&pageNumber=${pageNumber}&searchText=${searchText}`
    }`
  );
}

function fetchAllUsersByUserGroupId(userGroupId) {
  return api.get(
    `${properties.adminService}/email-user/getall?userGroupId=${userGroupId}`
  );
}

function deleteUser(userId) {
  return api.delete(`${properties.adminService}/email-user?userId=${userId}`);
}

export {
  addUser,
  fetchUser,
  updateUser,
  deleteUser,
  getAllUsersWithFilter,
  fetchAllUsersByUserGroupId
};
