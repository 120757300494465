import { properties } from "../properties";
import api from "../services/api";

function addRole(data) {
  return api.post(`${properties.adminService}/role`, data);
}

function fetchRole(id) {
  return api.get(`${properties.adminService}/role/${id}`);
}

function updateRole(id, data) {
  return api.put(`${properties.adminService}/role/${id}`, data);
}

function getAllRolesWithFilter(pageSize, pageNumber, searchText) {
  let url = `${properties.adminService}/role?`;
  if (pageSize && pageNumber != undefined) {
    url += `pageSize=${pageSize}&pageNumber=${pageNumber}`;
  }
  if (searchText) {
    url += `&searchText=${searchText}`;
  }
  return api.get(url);
}

function getAllPrivileges() {
  return api.get(`${properties.adminService}/privilege`);
}

function deleteRole(roleId) {
  return api.delete(`${properties.adminService}/role/${roleId}`);
}

function fetchPrivilege(roleId) {
  return api.get(`${properties.adminService}/role/${roleId}/privilege`)
}

export {
  addRole,
  fetchRole,
  updateRole,
  deleteRole,
  getAllRolesWithFilter,
  getAllPrivileges,
  fetchPrivilege
};
