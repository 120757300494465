import { LOGIN_REQUEST, SUCCESS, FAILURE, LOGOUT_REQUEST } from "./authTypes";

const initialState = {
  isLoggedIn: false,
  currentUser: {},
  tickets: [],
  isTokenRefreshing: false,
  isAutoRefresh: false,
  privileges: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
    case LOGOUT_REQUEST:
      return {
        isLoggedIn: false,
        currentUser: {},
        tickets: [],
        isTokenRefreshing: false,
        privileges: {}
      };
    case SUCCESS:
      return {
        ...state,
        isLoggedIn: action.payload.isLoggedIn,
        currentUser: action.payload.currentUser
      };
    case FAILURE:
      return {
        tickets: [],
        isLoggedIn: false,
        currentUser: {},
        isTokenRefreshing: false
      };
    case "SET_CURRENT_USER":
      return {
        ...state,
        isLoggedIn: action.payload.isLoggedIn,
        currentUser: action.payload.currentUser,
        privileges: action.payload.privileges
      };
    case "SET_TICKETS":
      return {
        ...state,
        tickets: action.payload
      };
    case "SET_REFRESH_STATUS":
      return {
        ...state,
        isTokenRefreshing: action.payload
      }
    case "SET_AUTO_REFRESH": {
      return {
        ...state,
        isAutoRefresh: action.payload
      }
    }
    default:
      return state;
  }
};

export default reducer;
