import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Toolbar,
  IconButton,
  Divider,
  InputLabel,
  TextField,
  Grid,
  FormControlLabel,
  Checkbox,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from "@mui/material";
import {
  fetchRole,
  updateRole,
  addRole,
  getAllPrivileges
} from "../../API/manageRoles";
import FormShimmering from "../utility/FormShimmer";
import CloseIcon from "@mui/icons-material/Close";
import FormFooter from "../utility/FormFooter";
import { PRIVILEGE_GROUP_LIST } from "../../utils/Privileges";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function RoleForm({
  setFeedback,
  setOpen,
  open,
  selectedRoleId,
  setFetchCount
}) {
  const formType = selectedRoleId ? "Update" : "Add";

  const [loading, setLoading] = useState(true);
  const [privilegeMap, setPrivilegeMap] = useState({});
  const [checked, setChecked] = useState({});

  useEffect(() => {
    getAllPrivileges()
      .then(({ data }) => {
        const privilegeIdMap = data.reduce(
          (acc, item) => ({ ...acc, [item.name]: item.id }),
          {}
        );
        setPrivilegeMap(privilegeIdMap);
      })
      .catch(() =>
        setFeedback({
          severity: "error",
          message: "There was an issue while fetching privileges.",
          state: true
        })
      );
  }, []);

  useEffect(() => {
    setLoading(true);
    if (formType === "Update") {
      fetchRole(selectedRoleId)
        .then(({ data }) => {
          formik.setValues({
            name: data.name,
            description: data.description,
            privilegeIds: data.privilegeIds
          });
          const privilegeIdsMap = data.privilegeIds.reduce(
            (acc, id) => ({ ...acc, [id]: true }),
            {}
          );
          setChecked(privilegeIdsMap);
        })
        .catch(() =>
          setFeedback({
            severity: "error",
            message: "There was an issue while fetching role.",
            state: true
          })
        )
        .finally(() => setLoading(false));
    } else {
      formik.resetForm();
      setChecked({});
      setLoading(false);
    }
  }, [selectedRoleId, formType, open]);

  const validationSchema = Yup.object({
    name: Yup.string()
      .max(20, "Role name must be 20 characters or less")
      .required("Required"),
    description: Yup.string()
      .max(200, "Description must be 200 characters or less")
      .required("Required")
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      description: ""
    },
    validationSchema,
    onSubmit: (values) => submitForm(values)
  });

  const submitForm = (values) => {    
    const privilegeIds = Object.keys(checked)
      .filter((key) => checked[key])
      .map((key) => Number(key));    

    if (privilegeIds.length === 0) {
      setFeedback({
        severity: "error",
        message: "Please select at least one privilege.",
        state: true
      });
      return;
    }

    const payload = { ...values, privilegeIds };
    const apiCall =
      formType === "Add"
        ? addRole(payload)
        : updateRole(selectedRoleId, payload);

    apiCall
      .then(() => {
        setFeedback({
          severity: "success",
          message: `Role ${
            formType === "Add" ? "added" : "updated"
          } successfully.`,
          state: true
        });
        setFetchCount((prevCount) => prevCount + 1);
        setOpen(false);
      })
      .catch(({ response }) =>
        setFeedback({
          severity: "error",
          message: response.data || "Error while processing request.",
          state: true
        })
      );
  };

  const handleClose = () => setOpen(false);

  const handlePrivilegeChange = (event) => {
    setChecked((prevChecked) => ({
      ...prevChecked,
      [event.target.name]: event.target.checked
    }));
  };

  return (
    <div
      className={`drawer-form-wrapper ${
        open ? "drawer-form-open" : "drawer-form-closed"
      }`}
      style={{ width: "60%" }}
    >
      <Toolbar />
      <br />
      <div className="close-button">
        <IconButton onClick={handleClose} sx={{ padding: 0 }}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className="drawer-form-container">
        {loading ? (
          <FormShimmering />
        ) : (
          <form>
            <h5>{formType} Role</h5>
            <Divider />
            <Grid
              container
              spacing={2}
              className="group-form-container"
              columns={24}
            >
              <Grid item md={12}>
                <div className="form-input">
                  <InputLabel
                    htmlFor="name"
                    className="form-input-label"
                    required
                  >
                    Name
                  </InputLabel>
                  <TextField
                    size="small"
                    id="name"
                    placeholder="e.g. Manager"
                    className="form-textfield"
                    fullWidth
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </div>

                <div className="form-input">
                  <InputLabel
                    htmlFor="description"
                    className="form-input-label"
                    required
                  >
                    Description
                  </InputLabel>
                  <TextField
                    size="small"
                    multiline
                    rows={3}
                    id="description"
                    placeholder="Describe the role"
                    className="form-textfield"
                    fullWidth
                    name="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.description &&
                      Boolean(formik.errors.description)
                    }
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                  />
                </div>
              </Grid>

              <Grid md={1} item sx={{ Height: "100%" }}>
                <Divider orientation="vertical" sx={{ opacity: 1 }} />
              </Grid>

              <Grid item md={11}>
                <div
                  className="form-input"
                  style={{
                    overflow: "auto",
                    maxHeight: "440px",
                    paddingLeft: "5px"
                  }}
                >
                  <InputLabel className="form-input-label" required>
                    Select Privileges
                  </InputLabel>
                  {PRIVILEGE_GROUP_LIST.map((group, index) => (
                    <Accordion key={index}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}-content`}
                        id={`panel${index}-header`}
                      >
                        <Typography>{group.title}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            ml: 2
                          }}
                        >
                          {group.privileges.map((privilege) => (
                            <FormControlLabel
                              key={privilege.name}
                              label={privilege.title}
                              control={
                                <Checkbox
                                  size="small"
                                  name={privilegeMap[privilege.name]}
                                  checked={
                                    checked[privilegeMap[privilege.name]] ||
                                    false
                                  }
                                  onChange={handlePrivilegeChange}
                                />
                              }
                            />
                          ))}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              </Grid>
            </Grid>
            <FormFooter formType={formType} handleClose={handleClose} handleSubmit={formik.handleSubmit}/>
          </form>
        )}
      </div>
    </div>
  );
}

export default RoleForm;
