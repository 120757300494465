import { useEffect, useState } from "react";
import { getHardDiskInfo } from "../../../API/devices";
import { useLocation } from "react-router-dom";
import { MESSAGES } from "../../../utils/Constants";
import TableComponent from "../../utility/Table";
import NoRecordsFound from "../../assets/Illustrator/NoRecordsFound";

export default function DeviceHardDiskInfo({ deviceId, setFeedback }) {
  const [diskDrives, setDiskDrives] = useState(null);
  const [logicalDiskPartitions, setLogicalDiskPartitions] = useState(null);

  useEffect(() => {
    getHardDiskInfo(deviceId)
      .then((response) => {
        setDiskDrives(response.data.diskDrives ? response.data.diskDrives : []);
        setLogicalDiskPartitions(
          response.data?.logicalDiskPartitions
            ? response.data.logicalDiskPartitions
            : []
        );
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: MESSAGES.GENERIC_EXCEPTION_MESSAGE,
          state: true
        });
      });
  }, []);

  return (
    <div>
      {diskDrives?.length === 0 ? (
        <div className="no-record-found-container">
          <div>
            <NoRecordsFound />
          </div>
          <div className="no-record-found-text">No Records Found</div>
          <br />
        </div>
      ) : (
        <div>
          <h6 style={{ textAlign: "left", fontWeight: "bold" }}>
            Disk Drives:
          </h6>
          <TableComponent
            headings={[
              "serialNumber",
              "model",
              "manufacturer",
              "description",
              "capacity"
            ]}
            data={diskDrives}
            styles={{
              table: { maxHeight: 425 }
            }}
          />
          <br />
          <h6 style={{ textAlign: "left", fontWeight: "bold" }}>
            Logical Disk Partitions:
          </h6>
          <TableComponent
            headings={[
              "serialNumber",
              "drive",
              "driveType",
              "fileSystem",
              "capacity",
              "freeSpace"
            ]}
            data={logicalDiskPartitions}
            styles={{
              table: { maxHeight: 425 }
            }}
          />
        </div>
      )}
    </div>
  );
}
