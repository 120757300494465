import { Button, Stack } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { createPasswordResetLink } from "../../API/userCredentials";
import Logo from "../assets/logo.svg";

function EmailSentPage({ setFeedback }) {
  let location = useLocation();
  const navigate = useNavigate();

  let message = "";

  if (location.state != null && location.state.message != null)
    message = location.state.message;

  function handleResend() {
    createPasswordResetLink(location.state.email)
      .then(() => {
        setFeedback({
          severity: "success",
          message: "Link resent. Please check your inbox.",
          state: true
        });
      })
      .catch((error) => {
        console.log(error.data);
      });
  }

  return (
    <div className="auth-form" style={{ paddingRight: 50 }}>
      <img className="logo" src={Logo} />
      <br />
      <br />
      <h1 className="auth-heading">Reset Password</h1>
      <div className="auth-subheading">
        A password reset link has been sent to the registered email. If you
        didnt receive the link please click on <b>Resend Link</b> below.
      </div>
      <br />
      <div className="form">
        <Stack spacing={2} direction="row">
          <Button onClick={handleResend} variant="outlined">
            Resend Link
          </Button>
          <Button onClick={() => navigate("/login")} variant="outlined">
            Cancel
          </Button>
        </Stack>
      </div>
    </div>
  );
}

export default EmailSentPage;
