import React, { useEffect } from "react";
import {
  CircularProgress,
  InputLabel,
  TextField,
  Button,
  Stack
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomTooltip from "../../../utils/Tooltip";

import {
  getAdDetails,
  registerAd,
  updateAdDetails,
  fetchUsersFromAd
} from "../../../API/ad";
import { useState } from "react";
import "./adForm.css";
import PageTitle from "../../utility/PageTitle";

function AdForm({ setFeedback }) {
  const navigate = useNavigate();
  const user = useSelector(({ auth }) => auth.currentUser);
  const [formType, setFormType] = useState("REGISTER");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAdDetails(user.userId)
      .then(({ data }) => {
        if (data !== "") {
          setFormType("UPDATE");
          formik.setValues({
            domainPort: data.port,
            domainBindDn: data.bindDn,
            domainHost: data.host,
            domainBindPass: data.bindPass,
            domainSearchFilter: data.searchFilter,
            domainSearchBase: data.searchBase
          });
        } else {
          formik.setValues({
            domainPort: "",
            domainBindDn: "",
            domainHost: "",
            domainBindPass: "",
            domainSearchFilter: "",
            domainSearchBase: ""
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  }, []);

  function submitForm(values, resetForm) {
    if (!loading) {
      setLoading(true);
      setFeedback({
        severity: "",
        message: "",
        state: false
      });

      let promise;

      if (formType === "UPDATE") promise = updateAdDetails(values);
      else promise = registerAd(values);

      promise
        .then((response) => {
          let message = "";
          if (formType === "UPDATE") message = "Configurations Updated.";
          else message = "Configurations Saved.";

          setLoading(false);
          setFormType("UPDATE");
          setFeedback({
            severity: "success",
            message: message,
            state: true
          });
        })
        .catch(function (error) {
          setLoading(false);
          setFeedback({
            severity: "error",
            message: "Failed to save. " + error.response.data,
            state: true
          });
        });
    }
  }

  const validate = Yup.object({
    domainHost: Yup.string().required("Required"),
    domainPort: Yup.number().required("Required").integer("Must be an integer"),
    domainBindDn: Yup.string().required("Required"),
    domainBindPass: Yup.string().required("Required"),
    domainSearchFilter: Yup.string().required("Required"),
    domainSearchBase: Yup.string().required("Required")
  });

  const formik = useFormik({
    initialValues: {
      domainPort: "",
      domainBindDn: "",
      domainHost: "",
      domainBindPass: "",
      domainSearchFilter: "",
      domainSearchBase: ""
    },
    validationSchema: validate,
    onSubmit: (values, { resetForm }) => {
      submitForm(values, resetForm);
    }
  });

  let heading = "Register Active Directory";
  if (formType === "UPDATE") heading = "Update Active Directory";

  const fetchFromAd = () => {
    if (!loading) {
      setLoading(true);
      fetchUsersFromAd()
        .then((response) => {
          setLoading(false);
          setFeedback({
            severity: "success",
            message: "Data fetched successfully.",
            state: true
          });
        })
        .catch((error) => {
          setLoading(false);
          setFeedback({
            severity: "error",
            message: error.response.data,
            state: true
          });
        });
    }
  };

  return (
    <>
      <div className="form-container">
        <form onSubmit={formik.handleSubmit}>
          <div className="form-input">
            <InputLabel
              htmlFor="domain-host"
              className="form-input-label"
              required
            >
              Domain Host &nbsp;
              <CustomTooltip title="The address or hostname of the LDAP server."></CustomTooltip>
            </InputLabel>
            <TextField
              size="small"
              fullWidth
              id="domain-host"
              name="domainHost"
              placeholder="ds.example.com"
              className="form-textfield"
              value={formik.values.domainHost}
              onChange={formik.handleChange}
              error={
                formik.touched.domainHost && Boolean(formik.errors.domainHost)
              }
              helperText={formik.touched.domainHost && formik.errors.domainHost}
            />
          </div>
          <div className="form-input">
            <InputLabel
              htmlFor="domain-port"
              className="form-input-label"
              required
            >
              Domain Port &nbsp;
              <CustomTooltip title="The port number used for connecting to the LDAP server."></CustomTooltip>
            </InputLabel>
            <TextField
              size="small"
              fullWidth
              id="domain-port"
              name="domainPort"
              placeholder="123"
              type="text"
              value={formik.values.domainPort}
              onChange={formik.handleChange}
              error={
                formik.touched.domainPort && Boolean(formik.errors.domainPort)
              }
              helperText={formik.touched.domainPort && formik.errors.domainPort}
            />
          </div>
          <div className="form-input">
            <InputLabel
              htmlFor="domain-bind-dn"
              className="form-input-label"
              required
            >
              Domain Bind DN &nbsp;
              <CustomTooltip title="The Distinguished Name (DN) used for binding or authenticating to the LDAP server."></CustomTooltip>
            </InputLabel>
            <TextField
              size="small"
              fullWidth
              id="domain-bind-dn"
              name="domainBindDn"
              placeholder="CN=Administrator,CN=Users,DC=example,DC=com"
              type="text"
              value={formik.values.domainBindDn}
              onChange={formik.handleChange}
              error={
                formik.touched.domainBindDn &&
                Boolean(formik.errors.domainBindDn)
              }
              helperText={
                formik.touched.domainBindDn && formik.errors.domainBindDn
              }
            />
          </div>
          <div className="form-input">
            <InputLabel
              htmlFor="domain-bind-pass"
              className="form-input-label"
              required
            >
              Domain Bind Pass &nbsp;
              <CustomTooltip title="The password associated with the provided Bind DN."></CustomTooltip>
            </InputLabel>
            <TextField
              size="small"
              fullWidth
              id="domain-bind-pass"
              name="domainBindPass"
              type="password"
              value={formik.values.domainBindPass}
              onChange={formik.handleChange}
              error={
                formik.touched.domainBindPass &&
                Boolean(formik.errors.domainBindPass)
              }
              helperText={
                formik.touched.domainBindPass && formik.errors.domainBindPass
              }
            />
          </div>
          <div className="form-input">
            <InputLabel
              htmlFor="domain-search-base"
              className="form-input-label"
              required
            >
              Domain Search Base &nbsp;
              <CustomTooltip title="The base DN for LDAP searches. It defines the starting point in the directory tree for searches, helping narrow down the search scope."></CustomTooltip>
            </InputLabel>
            <TextField
              size="small"
              fullWidth
              id="domain-search-base"
              name="domainSearchBase"
              placeholder="OU=YOUR_OU,DC=example,DC=com"
              type="text"
              value={formik.values.domainSearchBase}
              onChange={formik.handleChange}
              error={
                formik.touched.domainSearchBase &&
                Boolean(formik.errors.domainSearchBase)
              }
              helperText={
                formik.touched.domainSearchBase &&
                formik.errors.domainSearchBase
              }
            />
          </div>
          <div className="form-input">
            <InputLabel
              htmlFor="domain-search-filter"
              className="form-input-label"
              required
            >
              Domain Search Filter &nbsp;
              <CustomTooltip title="The filter used to specify the conditions for LDAP searches. It defines which entries match the search criteria."></CustomTooltip>
            </InputLabel>
            <TextField
              size="small"
              fullWidth
              id="domain-search-filter"
              name="domainSearchFilter"
              placeholder="(objectClass=*)"
              type="text"
              value={formik.values.domainSearchFilter}
              onChange={formik.handleChange}
              error={
                formik.touched.domainSearchFilter &&
                Boolean(formik.errors.domainSearchFilter)
              }
              helperText={
                formik.touched.domainSearchFilter &&
                formik.errors.domainSearchFilter
              }
            />
          </div>
          <Stack spacing={2} direction="row" justifyContent="flex-start">
            <Button
              className="primary-button-filled"
              variant="contained"
              type="submit"
              disabled={loading}
            >
              Save
            </Button>
            {loading ? (
              <div className="custom-float-right custom-loader">
                <CircularProgress size={18} color="inherit" />
                &nbsp; Testing configurations ..
              </div>
            ) : (
              formType === "UPDATE" && (
                <button
                  className="btn btn-primary custom-float-right"
                  type="button"
                  onClick={fetchFromAd}
                >
                  Import From AD
                </button>
              )
            )}
          </Stack>
        </form>
      </div>
    </>
  );
}

export default AdForm;
