import React from "react";
import Box from "@mui/material/Box";
import PageTitle from "../utility/PageTitle";
import { Outlet } from "react-router-dom";
import NavigationTabs from "../utility/NavigationTabs";
import { ADMIN_PRIVILEGE, PRIVILEGES } from "../../utils/Privileges";

function Customization({ setFeedback }) {
  const tabList = [
    {
      label: "Basic",
      path: "/customization/basic",
      requiredPrivileges: [ADMIN_PRIVILEGE]
    },
    {
      label: "Email Templates",
      path: "/customization/email-templates",
      requiredPrivileges: [PRIVILEGES.CONFIGURE_EMAIL_TEMPLATE.name]
    }
  ];

  return (
    <>
      <PageTitle />
      <br />
      <Box sx={{ width: "100%" }}>
        <NavigationTabs tabList={tabList} />
        <Box sx={{ p: 2 }}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
}

export default Customization;
