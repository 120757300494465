import { properties } from "../properties";
import api from "../services/api";

function createDashboardUser(values) {
  return api.post(`${properties.adminService}/dashboard-user/create`, values);
}

function getDashboardUser() {
  return api.get(`${properties.adminService}/dashboard-user`);
}


export {
  createDashboardUser,
  getDashboardUser
};
