import React from "react";
import ReportList from "./ReportList";

export default function EthernetReportList() {
  const headings = [
    "id",
    "deviceName",
    "incidentDttm",
    "policyName",
    "Ethernet Connection Name",
    "risk",
  ];
  const subheading = "Identify and analyze devices attempting to connect via Ethernet. ";
  return <ReportList headings={headings} destinationId={7} subheading={subheading} />;
}
