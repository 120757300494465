import Box from "@mui/material/Box";
import PageTitle from "../utility/PageTitle";
import NavigationTabs from "../utility/NavigationTabs";
import { Outlet } from "react-router-dom";
import { PRIVILEGES } from "../../utils/Privileges";

function HttpPolicy({ setFeedback }) {
  const tabList = [
    {
      label: "Domains & Categories",
      path: "/http",
      requiredPrivileges: [PRIVILEGES.VIEW_DOMAIN_AND_CATEGORY.name]
    },
    {
      label: "Policy",
      path: "/http/policy",
      requiredPrivileges: [PRIVILEGES.VIEW_POLICY.name]
    }
  ];
  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <PageTitle subheading="Whitelist Domains and Prevent Upload of Sensitive Data" />
        <br />
        <NavigationTabs tabList={tabList} />
        <Box sx={{ pt: 2 }}>
          <Outlet />
        </Box>
      </Box>
    </div>
  );
}

export default HttpPolicy;
