import { properties } from "../properties";
import api from "../services/api";

function createPasswordResetLink(email) {
  return api.post(`${properties.adminService}/user-credentials/password-reset-link`, {
    email
  });
}

function changePassword(data) {
  return api.put(
    `${properties.adminService}/user-credentials/password`,
    data
  );
}

export {
  createPasswordResetLink,
  changePassword
};
