import React from "react";
import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import { authenticateUser } from "../../services/index";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Logo from "../assets/logo.svg";
import Button from "@mui/material/Button";
import CookieService from "../../services/CookieService";
import { getSubDomain } from "../../utils/Helpers";

function LoginPage({ setFeedback }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validateUser = ({ email, password }) => {
    dispatch(authenticateUser(email, password))
      .then(() => {
        navigate("/dashboard", {
          state: { message: "Logged in Successfully" }
        });
      })
      .catch((error) => {
        setFeedback({
          message: error.response.data,
          severity: "error",
          state: true
        });
      });
  };

  const validate = Yup.object({
    email: Yup.string().email("Email is invalid").required("Email is required"),
    password: Yup.string().required("Password is required")
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema: validate,
    onSubmit: (values, { resetForm }) => {
      validateUser(values, resetForm);
    }
  });
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="auth-form">
      <img className="logo" src={Logo} />
      <br />
      <br />
      <h1 className="auth-heading">Welcome Back!</h1>
      <p className="auth-subheading">
        Enter your credentials to access your DLP Dashboard
      </p>
      <br />
      <form className="form" onSubmit={formik.handleSubmit}>
        <TextField
          autoComplete="email"
          fullWidth
          required
          className="input-field"
          placeholder="johndoe@company.com"
          name="email"
          type="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PermIdentityIcon />
              </InputAdornment>
            ),
            style: {
              fontSize: "16px"
            }
          }}
          InputLabelProps={{
            style: { color: "#848484", fontSize: "20px" }
          }}
        />
        <br />
        <TextField
          autoComplete="on"
          className="input-field"
          fullWidth
          required
          placeholder="*******"
          name="password"
          type={showPassword ? "text" : "password"}
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <LockOutlinedIcon sx={{ height: "24px", width: "24px" }} />
              </InputAdornment>
            ),
            style: {
              fontSize: "20px"
            }
          }}
          InputLabelProps={{ style: { color: "#848484", fontSize: "15px" } }}
        />
        <br />
        <Button
          className="submit-button"
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
        >
          LOGIN
        </Button>
        {getSubDomain() ? null : (
          <Button
            className="submit-button"
            type="button"
            fullWidth
            variant="outlined"
            color="primary"
            style={{ marginTop: 9, backgroundColor: "transparent" }}
            onClick={() => navigate("/signup")}
          >
            SIGN UP
          </Button>
        )}
      </form>
      <div
        style={{
          alignItems: "flex-start",
          paddingTop: 3
        }}
        className="form"
      >
        <Link to={"/forgot-password"}>Forgot Password</Link>
      </div>
    </div>
  );
}

export default LoginPage;
