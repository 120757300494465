import { BarChart, LineChart, PieChart } from "@mui/x-charts";
import SummaryCard from "./SummaryCard";
import PoliciesEnabledIcon from "../assets/icons/PoliciesEnabledIcon";
import RiskIcon from "../assets/icons/RiskIcon";
import ChartCard from "./ChartCard";
import TableComponent from "../utility/Table";
import DeviceStatusCard from "./DeviceStatusCard";
import DeviceIcon from "../assets/icons/DeviceIcon";
import { useEffect } from "react";
import { getDeviceCount } from "../../API/devices";
import { useState } from "react";
import { getEnabledPolicyCount } from "../../API/policy";
import {
  getHighRiskEventCount,
  getTopPoliciesBreached,
  getTopRiskyUsers,
  getTopRiskyDevices
} from "../../API/reporting";
import { BarChartWrapper } from "./BarChartWrapper";
import LineChartWrapper from "./LineChartWrapper";
import SummaryCardShimmering from "./SummaryCardShimmering";
import ChartShimmering from "./ChartsShimmering";
import "./css/dashboard.css";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const [deviceCount, setDeviceCount] = useState(0);
  const [activeDeviceCount, setActiveDeviceCount] = useState(0);
  const [inactiveDeviceCount, setInactiveDeviceCount] = useState(0);
  const [enabledPolicyCount, setEnabledPolicyCount] = useState(0);
  const [highRiskEventCount, setHighRiskEventCount] = useState(0);
  const [pieChartValuesAndLabels, setPieChartValuesAndLabels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [lineChartValuesAndLabels, setLineChartValuesAndLabels] = useState([
    { day: 0, count: 0 }
  ]);
  const [riskyDevicesValuesAndLabels, setRiskyDevicesValuesAndLabels] =
    useState([]);
  const [tabValue, setTabValue] = useState(0);

  const navigate = useNavigate();
  useEffect(() => {
    const loadData = async () => {
      try {
        await getDeviceCount()
          .then((response) => setDeviceCount(response.data))
          .catch((error) => console.log(error));

        await getDeviceCount(true)
          .then((response) => setActiveDeviceCount(response.data))
          .catch((error) => console.log(error));

        await getDeviceCount(false)
          .then((response) => setInactiveDeviceCount(response.data))
          .catch((error) => console.log(error));

        await getEnabledPolicyCount()
          .then((response) => setEnabledPolicyCount(response.data))
          .catch((error) => console.log(error));

        await getHighRiskEventCount()
          .then((response) => setHighRiskEventCount(response.data))
          .catch((error) => console.log(error));

        await getTopPoliciesBreached(5)
          .then((response) => setPieChartValuesAndLabelsData(response.data))
          .catch((error) => console.log(error));
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (tabValue == 0)
      getTopRiskyDevices(5)
        .then(({ data }) => {
          const devices = data.map((device) => ({
            name: device.name || "Deleted Device",
            value: device.value
          }));
          setRiskyDevicesValuesAndLabels(devices);
        })
        .catch((error) => console.log(error));
    else {
      getTopRiskyUsers(5)
        .then(({ data }) => {
          const modifiedData = data.map((user) => ({
            name: user.name || "Deleted User",
            value: user.value
          }));
          setRiskyDevicesValuesAndLabels(modifiedData);
        })
        .catch((error) => console.log(error));
    }
  }, [tabValue]);

  function setPieChartValuesAndLabelsData(data) {
    const pieChartArray = data.map((item) => {
      const label = item.name ? item.name : "Deleted Policy";
      return { value: parseInt(item.value), label };
    });
    setPieChartValuesAndLabels(pieChartArray);
  }

  const PieChartWrapper = () => {
    useEffect(() => {
      const legendLabels = document.querySelectorAll(".MuiChartsLegend-label");
      legendLabels.forEach((label) => {
        const labelText = label.innerHTML;
        if (labelText === "Deleted Policy") {
          label.style.fill = "red";
        }
      });
    }, [pieChartValuesAndLabels]);

    return (
      <PieChart
        colors={["#2D3AD2", "#505CEA", "#727CF5", "#A7ADFA", "#DEE0F5"]}
        sx={{
          [`& .${"MuiChartsLegend-label"}`]: {
            fontSize: 12,
            color: "black",
            fontFamily: "DM sans",
            wordWrap: "break-word"
          }
        }}
        series={[
          {
            data: pieChartValuesAndLabels,
            innerRadius: 60
          }
        ]}
        margin={{ right: 140 }}
      />
    );
  };

  return (
    <div>
      {loading ? (
        <SummaryCardShimmering isLoading={true} />
      ) : (
        <div className="summary-cards">
          <SummaryCard
            heading="Enrolled Devices"
            icon={DeviceIcon}
            count={deviceCount}
            onClickHandler={() => navigate("/view-devices")}
          />
          <DeviceStatusCard
            active={activeDeviceCount}
            inactive={inactiveDeviceCount}
            onClickHandler={() =>
              navigate("/view-devices", {
                state: {
                  isActive: true
                }
              })
            }
          />
          <SummaryCard
            heading="Active Policies"
            icon={PoliciesEnabledIcon}
            count={enabledPolicyCount}
            onClickHandler={() => navigate("/usb/policy")}
          />
          <SummaryCard
            heading="High Risk Incidents"
            icon={RiskIcon}
            count={highRiskEventCount}
            onClickHandler={() => navigate("/device-reports")}
          />
        </div>
      )}
      <div>
        <div className="chart-card">
          <div className="dlp-incident">
            <ChartCard
              chart={isLoading ? ChartShimmering : LineChartWrapper}
              heading={"DLP Incidents"}
              style={{
                minWidth: 520,
                height: 300
              }}
              dataLength={1}
              showFilter={true}
            />
          </div>
          <div className="policies-breached">
            <ChartCard
              chart={PieChartWrapper}
              heading={"Top 5 Policies Breached"}
              dataLength={pieChartValuesAndLabels.length}
              style={{ width: 400, height: 220, marginBottom: "80px" }}
            />
          </div>
        </div>

        {/* <div className="highest-risk">
          <ChartCard
            chart={() => (
              <div>
                <Tabs
                  activeKey={tabValue}
                  onSelect={(k) => setTabValue(k)}
                  variant="tabs"
                  className="customTabs"
                  style={{
                    backgroundColor: "white",
                    borderBottom: "1px solid #E8E8E8"
                  }}
                >
                  <Tab
                    eventKey={0}
                    title="Devices"
                    style={{ borderColor: "white" }}
                  ></Tab>
                  <Tab eventKey={1} title="Users"></Tab>
                </Tabs>

                <br />
                <TableComponent
                  headings={["name", "value"]}
                  data={riskyDevicesValuesAndLabels}
                  customColumnNames={{
                    value:
                      tabValue === 0 ? "Total Incidents" : "Total Incidents"
                  }}
                />
              </div>
            )}
            heading={"Highest Risk Devices And Users "}
            style={{ padding: "0px 30px 20px 30px", width: 400 }}
            dataLength={1}
          />

          <ChartCard
            chart={BarChartWrapper}
            heading={"Top 5 Rules Breached"}
            style={{ width: 675, height: 300 }}
          />
        </div> */}
      </div>
    </div>
  );
}

export default Dashboard;
