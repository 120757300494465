import React from "react";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import CustomTooltip from "../../utils/Tooltip";

export default function PasswordConfiguration({ config, setConfig }) {
  return (
    <div style={{ textAlign: "left" }}>
      <h6 style={{ fontWeight: "bold" }}>Pin Pattern</h6>
      <br />
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={5}>
          <Typography textAlign="left">
            Require at least one lowercase letter (a-z)
            <CustomTooltip title="Require users to include at least one lowercase letter in the PIN." />
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Checkbox
            checked={config.pin.pinPattern.requireLowerCase === true}
            onChange={(e) => {
              const updatedConfig = { ...config };
              updatedConfig.pin.pinPattern.requireLowerCase = e.target.checked;
              setConfig(updatedConfig);
            }}
          />
        </Grid>
        <Grid item xs={5}>
          <Typography textAlign="left">
            Require at least one uppercase letter (A-Z)
            <CustomTooltip title="Require users to include at least one uppercase letter in the PIN." />
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Checkbox
            checked={config.pin.pinPattern.requireUpperCase === true}
            onChange={(e) => {
              const updatedConfig = { ...config };
              updatedConfig.pin.pinPattern.requireUpperCase = e.target.checked;
              setConfig(updatedConfig);
            }}
          />
        </Grid>
        <Grid item xs={5}>
          <Typography textAlign="left">
            Require at least one number (0-9)
            <CustomTooltip title="Require users to include at least one numerical digit in the PIN." />
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Checkbox
            checked={config.pin.pinPattern.requireDigits === true}
            onChange={(e) => {
              const updatedConfig = { ...config };
              updatedConfig.pin.pinPattern.requireDigits = e.target.checked;
              setConfig(updatedConfig);
            }}
          />
        </Grid>
        <Grid item xs={5}>
          <Typography textAlign="left">
            Require at least one special character (@%+/’!#$?:(){}[]~-_.)
            <CustomTooltip title="Require users to include at least one special character in the PIN." />
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Checkbox
            checked={config.pin.pinPattern.requireSpecialCharacters === true}
            onChange={(e) => {
              const updatedConfig = { ...config };
              updatedConfig.pin.pinPattern.requireSpecialCharacters =
                e.target.checked;
              setConfig(updatedConfig);
            }}
          />
        </Grid>
        <Grid item xs={5}>
          <Typography textAlign="left">
            Maximum Length
            <CustomTooltip title="The maximum number of characters allowed in the PIN." />
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <input
            type="number"
            inputProps={{ min: 0 }}
            size="small"
            value={config.pin.pinPattern.maxLength}
            onChange={(e) => {
              const updatedConfig = { ...config };
              updatedConfig.pin.pinPattern.maxLength = e.target.value.replace(
                /\D/g,
                ""
              );
              setConfig(updatedConfig);
            }}
            style={{ width: "150px", padding: "8px", fontSize: "small" }}
          />
        </Grid>
        <Grid item xs={5}>
          <Typography textAlign="left">
            Minimum Length
            <CustomTooltip title="The minimum number of characters required for the PIN." />
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <input
            type="number"
            inputProps={{ min: 0 }}
            size="small"
            value={config.pin.pinPattern.minLength}
            onChange={(e) => {
              const updatedConfig = { ...config };
              updatedConfig.pin.pinPattern.minLength = e.target.value.replace(
                /\D/g,
                ""
              );
              setConfig(updatedConfig);
            }}
            style={{ width: "150px", padding: "8px", fontSize: "small" }}
          />
        </Grid>
        <Grid item xs={5}>
          <Typography textAlign="left">
            Number of Repeats Allowed
            <CustomTooltip title="Specifies the number of previously used pins that cannot be reused again." />
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <input
            type="number"
            inputProps={{ min: 0 }}
            size="small"
            value={config.pin.numberOfRepeatsAllowed}
            onChange={(e) => {
              const updatedConfig = { ...config };
              updatedConfig.pin.numberOfRepeatsAllowed = e.target.value;
              setConfig(updatedConfig);
            }}
            style={{ width: "150px", padding: "8px", fontSize: "small" }}
          />
        </Grid>
        <Grid item xs={5}>
          <Typography textAlign="left">
            Pin Expiry
            <CustomTooltip title="Pin expiration in days." />
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <input
            type="number"
            inputProps={{ min: 0 }}
            size="small"
            value={config.pin.expiryDays}
            onChange={(e) => {
              const updatedConfig = { ...config };
              updatedConfig.pin.expiryDays = e.target.value;
              setConfig(updatedConfig);
            }}
            style={{ width: "150px", padding: "8px", fontSize: "small" }}
          />
          <span style={{ paddingLeft: "12px" }}>days</span>
        </Grid>
      </Grid>
    </div>
  );
}
