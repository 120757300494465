import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Toolbar,
  IconButton,
  Divider,
  InputLabel,
  TextField,
  Autocomplete
} from "@mui/material";
import { useEffect } from "react";
import { fetchUser, updateUser, addUser } from "../../API/manageUsers";
import { getAllRolesWithFilter } from "../../API/manageRoles";
import FormShimmering from "../utility/FormShimmer";
import CloseIcon from "@mui/icons-material/Close";
import FormFooter from "../utility/FormFooter";

function UserForm({
  setFeedback,
  setOpen,
  open,
  selectedUserId,
  setFetchCount
}) {
  let formType = selectedUserId ? "Update" : "Add";

  const [loading, setLoading] = useState(true);
  const [roleList, setRoleList] = useState([]);

  useEffect(() => {
    getAllRolesWithFilter()
      .then(({ data }) => {
        const processedData = data.data.map((role) => {
          return {
            name: role.name,
            id: role.id
          };
        });
        setRoleList(processedData);
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "There is an issue while fetching Roles!",
          state: true
        });
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    if (formType === "Update") {
      fetchUser(selectedUserId)
        .then((response) => {
          formik.setValues(response.data);
        })
        .catch((error) =>
          setFeedback({
            severity: "error",
            message: "There is an issue while updating.",
            state: true
          })
        )
        .finally(() => {
          setLoading(false);
        });
    } else {
      formik.resetForm();
      setLoading(false);
    }
  }, [selectedUserId, open]);

  const updateValidation = Yup.object({
    firstName: Yup.string()
      .max(20, "Must be 20 characters or less")
      .required("Required"),
    lastName: Yup.string().max(20, "Must be 20 characters or less"),
    email: Yup.string().email("Email is invalid").required("Email is required"),
    roleId: Yup.number().required()
  });

  const saveValidation = Yup.object({
    firstName: Yup.string()
      .max(20, "Must be 20 characters or less")
      .required("Required"),
    lastName: Yup.string().max(20, "Must be 20 characters or less"),
    email: Yup.string().email("Email is invalid").required("Email is required"),
    roleId: Yup.number().required(),
    password: Yup.string().required()
  });

  const formik = useFormik({
    initialValues: {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      ...(selectedUserId ? {} : { password: "" }),
      roleId: null
    },
    validationSchema: selectedUserId ? updateValidation : saveValidation,
    onSubmit: (values, { resetForm }) => {
      submitForm(values, resetForm);
    }
  });

  function submitForm(values, resetForm) {
    let promise;
    if (formType === "Add")
      promise = addUser({
        ...values
      });
    else {
      promise = updateUser(selectedUserId, {
        ...values
      });
    }

    promise
      .then((response) => {
        const message = formType === "Add" ? "added" : "updated";
        setFeedback({
          severity: "success",
          message: `User ${message} Successfully`,
          state: true
        });
        setFetchCount((fetchCount) => fetchCount + 1);
        setOpen(false);
      })
      .catch(function (error) {
        setFeedback({
          severity: "error",
          message: error.response.data,
          state: true
        });
      });
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div
      className={`drawer-form-wrapper ${
        open ? "drawer-form-open" : "drawer-form-closed"
      }`}
    >
      <Toolbar />
      <br />
      <div className="close-button">
        <IconButton onClick={handleClose} sx={{ padding: 0 }}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className="drawer-form-container">
        {loading ? (
          <FormShimmering />
        ) : (
          <form>
            <h5>{formType} User</h5>
            <Divider />
            <div className="form-input">
              <InputLabel
                htmlFor="lastName"
                className="form-input-label"
                required
              >
                Email
              </InputLabel>
              <TextField
                size="small"
                id="email"
                placeholder="Email"
                className="form-textfield"
                fullWidth
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </div>
            <div className="form-input">
              <InputLabel
                htmlFor="firstName"
                className="form-input-label"
                required
              >
                First Name
              </InputLabel>
              <TextField
                size="small"
                id="firstName"
                placeholder="First Name"
                className="form-textfield"
                fullWidth
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
            </div>
            <div className="form-input">
              <InputLabel htmlFor="lastName" className="form-input-label">
                Last Name
              </InputLabel>
              <TextField
                size="small"
                id="lastName"
                placeholder="Last Name"
                className="form-textfield"
                fullWidth
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
            </div>
            {selectedUserId ? null : (
              <div className="form-input">
                <InputLabel
                  htmlFor="password"
                  className="form-input-label"
                  required
                >
                  Password
                </InputLabel>
                <TextField
                  size="small"
                  id="password"
                  placeholder="Password"
                  className="form-textfield"
                  fullWidth
                  type="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
              </div>
            )}
            <div className="form-input">
              <InputLabel className="form-input-label" required>
                Role
              </InputLabel>
              <Autocomplete
                size="small"
                id="roleId"
                options={roleList}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Role" />
                )}
                value={roleList.find((role) => role.id == formik.values.roleId)}
                onChange={(event, newValue) => {
                  formik.setFieldValue("roleId", newValue?.id);
                }}
                noOptionsText={"Please create a role"}
              />
            </div>
            <br />
            <FormFooter
              formType={formType}
              handleClose={handleClose}
              handleSubmit={formik.handleSubmit}
            />
          </form>
        )}
      </div>
    </div>
  );
}

export default UserForm;
