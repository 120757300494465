import React, { useState, useEffect } from "react";
import { deleteUser, getAllUsersWithFilter } from "../../API/manageUsers";
import TableComponent from "../utility/Table";
import Button from "@mui/material/Button";
import UserForm from "./UserForm";
import SearchBar from "../utility/SearchBar";
import { DIALOG_TYPES } from "../../utils/Constants";
import CustomDialog from "../utility/Dialog";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import Pagination from "../utility/Pagination";
import CreateButton from "../utility/CreateButton";
import { PRIVILEGES } from "../../utils/Privileges";

function DashboardUserList({ setFeedback }) {
  const [admins, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [fetchCount, setFetchCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    getAllUsersWithFilter(pageSize, pageNumber, searchText)
      .then(({ data }) => {
        setUsers(data.data);
        setTotalCount(data.totalCount);
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "There is an issue while fetching admins!",
          state: true
        });
      });
  }, [fetchCount, pageNumber, searchText, pageSize]);

  const handleDelete = (id) => {
    setIsDeleteConfirmationOpen(true);
    setSelectedId(id);
  };

  const handleConfirmDelete = () => {
    deleteUser(selectedId)
      .then(function (response) {
        setUsers(admins.filter((admin) => admin.id !== selectedId));
        setFeedback({
          severity: "success",
          message: "User deleted successfully",
          state: true
        });
        setFetchCount((prev) => prev + 1);
      })
      .catch(function (error) {
        setFeedback({
          severity: "error",
          message: error.response.data,
          state: true
        });
      })
      .finally(() => {
        setIsDeleteConfirmationOpen(false);
        setSelectedId("");
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
    setSelectedId(null);
  };

  const handleView = (id) => {
    setOpen(true);
    setSelectedId(id);
  };

  return (
    <div>
      <div className="main-title-heading-container">
        <SearchBar
          setSearchText={setSearchText}
          searchText={searchText}
          placeholder={"Search by Name"}
        />
        <CreateButton
        label={"Create User"}
        onClickHandler={handleClickOpen}
        startIcon={<PersonAddAlt1Icon/>}
        />
      </div>

      <TableComponent
        headings={["id", "email", "firstName", "lastName", "roleName"]}
        data={admins}
        customColumnNames={{ roleName: "Role" }}
        options={{ hideIds: true }}
        actions={[
          {
            label: "Edit",
            onClickHandler: handleView,
            hasParameter: true
          },
          {
            label: "Delete",
            onClickHandler: handleDelete,
            hasParameter: true
          }
        ]}
        styles={{
          table: { maxHeight: 425 }
        }}
      />
      {totalCount != 0 ? (
        <div className="pagination">
          <Pagination
            totalCount={totalCount}
            page={pageNumber}
            setPage={setPageNumber}
            rowsPerPage={pageSize}
            setRowsPerPage={setPageSize}
          />
        </div>
      ) : null}
      <UserForm
        open={open}
        setOpen={setOpen}
        setFeedback={setFeedback}
        selectedUserId={selectedId}
        setFetchCount={setFetchCount}
      />
      <CustomDialog
        open={isDeleteConfirmationOpen}
        title="Confirm Deletion"
        content="Are you sure you want to delete?"
        setOpen={setIsDeleteConfirmationOpen}
        handleConfirm={handleConfirmDelete}
        dialogType={DIALOG_TYPES.CONFIRM}
      />
    </div>
  );
}

export default DashboardUserList;
