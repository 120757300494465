import React from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Fade from "@mui/material/Fade"; 

const CustomTooltip = ({
  title,
  arrow = true, 
  placement = "right",
  TransitionComponent = Fade,
  TransitionProps = { timeout: 600 },
  style = {},
  iconButtonStyle = {}
}) => {
  return (
    <Tooltip
      title={
        <span style={{ fontSize: "14px", fontFamily: "DM Sans, sans-serif", ...style }}>
          {title}
        </span>
      }
      arrow={arrow}
      placement={placement}
      TransitionComponent={TransitionComponent}
      TransitionProps={TransitionProps}
    >
      <IconButton
        sx={{ marginTop: "-4px", marginLeft: "-4px", ...iconButtonStyle }}
      >
        <InfoOutlinedIcon sx={{ width: "20px" }} />
      </IconButton>
    </Tooltip>
  );
};

export default CustomTooltip;
