import { useState } from "react";
import TableComponent from "../utility/Table";
import { Button, Box, Typography } from "@mui/material";
import {
  deleteGroup,
  fetchAllGroupsByTypeAndPagination
} from "../../API/group";
import GroupForm from "./GroupForm";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import "./device-group.css";
import PageTitle from "../utility/PageTitle";
import SearchBar from "../utility/SearchBar";
import { DIALOG_TYPES } from "../../utils/Constants";
import CustomDialog from "../utility/Dialog";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import Pagination from "../utility/Pagination";
import CreateButton from "../utility/CreateButton";
import { PRIVILEGES } from "../../utils/Privileges";

const label = { inputProps: { "aria-label": "Switch demo" } };

function UserGroupList({ setFeedback }) {
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [data, setData] = useState(null);
  const [fetchCount, setFetchCount] = useState(0);
  const [devicesData, setDevicesData] = useState([]);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const user = useSelector(({ auth }) => auth.currentUser);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    fetchAllGroupsByTypeAndPagination("USER", pageSize, pageNumber, searchText)
      .then(({ data }) => {
        setData(data.GroupList);
        setTotalCount(data.totalCount);
      })
      .catch((error) => console.log(error.response.data));
  }, [fetchCount, pageNumber, searchText, pageSize]);

  const handleClickOpen = () => {
    setOpen(true);
    setSelectedId(null);
  };
  const handleDelete = (id) => {
    setIsDeleteConfirmationOpen(true);
    setSelectedId(id);
  };

  const handleConfirmDelete = () => {
    deleteGroup(selectedId)
      .then((response) => {
        setData(data.filter((group) => group.id !== selectedId));
        setFeedback({
          severity: "success",
          message: "Group deleted successfully",
          state: true
        });
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "Something went wrong",
          state: true
        });
      })
      .finally(() => {
        setIsDeleteConfirmationOpen(false);
        setSelectedId("");
      });
  };

  const handleView = (id) => {
    setOpen(true);
    setSelectedId(id);
  };

  const headings = ["id", "name", "origin"];

  return (
    <div>
      <div className="main-title-heading-container">
        <PageTitle subheading="Manage User Groups and Keep Your users Organized" />
        <div className="add">
          <CreateButton
            label={"Add Group"}
            onClickHandler={handleClickOpen}
            startIcon={<GroupAddIcon />}
            requiredPrivileges={[PRIVILEGES.CREATE_EMAIL_USER_GROUP.name]}
          />
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <SearchBar
          setSearchText={setSearchText}
          searchText={searchText}
          placeholder={"Search by Group Name"}
        />
      </div>
      <TableComponent
        headings={headings}
        data={data}
        options={{ hideIds: true }}
        actions={[
          {
            label: "Edit",
            onClickHandler: handleView,
            hasParameter: true,
            requiredPrivileges: [PRIVILEGES.EDIT_EMAIL_USER_GROUP.name]
          },
          {
            label: "Delete",
            onClickHandler: handleDelete,
            hasParameter: true,
            requiredPrivileges: [PRIVILEGES.DELETE_EMAIL_USER_GROUP.name]
          }
        ]}
        styles={{
          table: { maxHeight: 480 }
        }}
      />
      {totalCount != 0 ? (
        <div className="pagination">
          <Pagination
            totalCount={totalCount}
            page={pageNumber}
            setPage={setPageNumber}
            rowsPerPage={pageSize}
            setRowsPerPage={setPageSize}
          />
        </div>
      ) : null}
      <GroupForm
        open={open}
        setOpen={setOpen}
        setFeedback={setFeedback}
        selectedGroupId={selectedId}
        setFetchCount={setFetchCount}
        groupType={"USER"}
      />
      <CustomDialog
        open={isDeleteConfirmationOpen}
        title="Confirm Deletion"
        content="Are you sure you want to delete?"
        setOpen={setIsDeleteConfirmationOpen}
        handleConfirm={handleConfirmDelete}
        dialogType={DIALOG_TYPES.CONFIRM}
      />
    </div>
  );
}

export default UserGroupList;
