import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import NavigationBar from "./NavigationBar";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Collapse } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { drawerItems as unfilteredDrawerItems } from "../../utils/data";
import { ListItemIcon, Typography } from "@mui/material";
import MiniOrangeIcon from "../assets/icons/MiniOrangeIcon";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import "./drawer-and-navigation.css";
import { reportingDrawerItems } from "../../utils/data";
import CheckPrivilege from "../utility/CheckPrivilege";
const drawerWidth = 200;

export default function DrawerAndNavigationBar() {
  let currentPath = window.location.pathname;
  const [selectionChangedCount, setSelectionChangedCount] = useState(0);
  const [openObject, setOpenObject] = useState({});
  const [drawerItems, setDrawerItems] = useState([]);
  const [issubitem, setissubitem] = useState({});
  const location = useLocation();
  const isActive = location.pathname === issubitem.itemLink;
  const user = useSelector(({ auth }) => auth.currentUser);

  function setDrawerItemsForCustomer() {
    let allowedFeatures;
    if (user.userId === 8574921) {
      allowedFeatures = ["ETHERNET", "DEVICES"];
    } else
      allowedFeatures = [
        "POLICIES",
        "USB",
        "ETHERNET",
        "CLIPBOARD",
        "SCREENSHOT",
        "SITE_BLOCKING",
        "APP_BLOCKING",
        "EMAIL_BLOCKING",
        "PASSWORD_CONFIGURATION",
        "SMTP_CONFIGURATION",
        "ACTIVE_DIRECTORY",
        "CLASSIFICATION",
        "USERS",
        "DEVICES"
      ];
    const allowedFeaturesSet = new Set(allowedFeatures);
    allowedFeaturesSet.add("GENERAL");
    if (user.roleId == 1) allowedFeaturesSet.add("MANAGE_ROLES_AND_USERS");
    let tempDrawerItems = [];
    unfilteredDrawerItems.forEach((item) => {
      if (item.subItems == undefined) {
        if (allowedFeaturesSet.has(item.featureName))
          tempDrawerItems.push(item);
      } else {
        let tempDrawerSubItems = [];
        item.subItems.forEach((subItem) => {
          if (allowedFeaturesSet.has(subItem.featureName))
            tempDrawerSubItems.push(subItem);
        });
        if (tempDrawerSubItems.length > 0) {
          item.subItems = tempDrawerSubItems;
          tempDrawerItems.push(item);
        }
      }
    });
    setDrawerItems(tempDrawerItems);
  }

  useEffect(() => setDrawerItemsForCustomer(), [user]);

  const listbutton = {
    width: drawerWidth,
    paddingRight: 15,
    paddingLeft: 15,
    paddingTop: 12,
    paddingBottom: 12
  };

  useEffect(() => {
    let temp = {};
    drawerItems.forEach((item) => {
      temp[item.itemName] = false;
    });
    setOpenObject(temp);
  }, []);

  useEffect(() => {
    const isReportingDashboard = location.pathname.startsWith("/reporting/");

    if (isReportingDashboard) {
      setDrawerItems(reportingDrawerItems);
    } else {
      setDrawerItemsForCustomer();
    }
  }, [location.pathname]);

  const isDrawerItemActive = (drawerItemPath) => {
    //  Determines if the current path corresponds to the specified drawer item path or any of its child paths.
    return currentPath === drawerItemPath || currentPath.startsWith(drawerItemPath + "/");
  };

  return (
    <div className="side-bar">
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          boxShadow: "unset"
        }}
        onClick={() => {
          setSelectionChangedCount(selectionChangedCount + 1);
        }}
      >
        <NavigationBar />
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            boxSizing: "border-box",
            color: "303030",
            background: "#FFFFFF",
            boxShadow: "1px 4px 4px 0px rgba(0 0 0 / 0.1)",
            borderRight: "1px solid rgba(235 235 235 / 1)",
            zIndex: "unset"
          }
        }}
      >
        <Toolbar />
        <Box className="nav-drawer">
          <List
            component="nav"
            aria-label="main mailbox folders"
            className="nav-items"
          >
            {drawerItems.map((item, index1) => (
              <CheckPrivilege
                requiredPrivileges={item.requiredPrivileges}
                key={index1}
              >
                <div>
                  {item.subItems != undefined ? (
                    <div>
                      <ListItemButton
                        onClick={() => {
                          setOpenObject((prevOpenObject) => {
                            const newOpenObject = { ...prevOpenObject };
                            Object.keys(newOpenObject).forEach((key) => {
                              if (key !== item.itemName) {
                                newOpenObject[key] = false;
                              }
                            });
                            newOpenObject[item.itemName] =
                              !prevOpenObject[item.itemName];
                            if (newOpenObject[item.itemName]) {
                              newOpenObject[item.itemName] = true;
                            }

                            return newOpenObject;
                          });
                        }}
                        selected={openObject[item.itemName]}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: "white",
                            color: "#3858E9"
                          }
                        }}
                      >
                        <div className="subitem">
                          <item.ItemIcon />
                          <ListItemText
                            primary={item.itemName}
                            sx={{ marginLeft: 2 }}
                          />
                        </div>
                        <div className="expand-icons">
                          {openObject[item.itemName] ? (
                            <ExpandLess className="expand-less" />
                          ) : (
                            <ArrowForwardIosSharpIcon className="expand-more" />
                          )}
                        </div>
                      </ListItemButton>
                      <Collapse
                        in={openObject[item.itemName]}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List
                          component="div"
                          disablePadding
                          style={{ marginTop: "5px", marginBottom: "5px" }}
                        >
                          {item.subItems.map((subItem, index2) => (
                            <CheckPrivilege
                              requiredPrivileges={subItem.requiredPrivileges}
                              key={index2}
                            >
                              <div>
                                {subItem.itemWork != undefined ? (
                                  <div className="submenu">
                                    <ListItem key={index2} disablePadding>
                                      <ListItemButton
                                        className="subitems"
                                        disabled
                                      >
                                        <div className={"subitems-nav-link"}>
                                          <div className="subitem-name"></div>
                                          {subItem.itemName}
                                        </div>
                                      </ListItemButton>
                                    </ListItem>
                                  </div>
                                ) : (
                                  <NavLink
                                    key={index2}
                                    to={subItem.itemLink}
                                    className="submenu"
                                  >
                                    <ListItem key={index2} disablePadding>
                                      <div className="submenu-list-item">
                                        <ListItemButton
                                          className="subitems"
                                          selected={isDrawerItemActive(subItem.itemLink)}
                                        >
                                          <div
                                            className={
                                              isDrawerItemActive(subItem.itemLink)
                                                ? "nav-link active"
                                                : "nav-link"
                                            }
                                            onClick={(event) => {
                                              setissubitem(item);
                                              setSelectionChangedCount(
                                                selectionChangedCount + 1
                                              );
                                            }}
                                          >
                                            <div className="subitem-name"></div>
                                            {subItem.itemName}
                                          </div>
                                        </ListItemButton>
                                      </div>
                                    </ListItem>
                                  </NavLink>
                                )}
                              </div>
                            </CheckPrivilege>
                          ))}
                        </List>
                      </Collapse>
                    </div>
                  ) : (
                    <ListItemButton
                      onClick={() => {
                        setOpenObject((prevOpenObject) => {
                          const newOpenObject = { ...prevOpenObject };
                          Object.keys(newOpenObject).forEach((key) => {
                            if (key !== item.itemName) {
                              newOpenObject[key] = false;
                            }
                            if (key !== issubitem.itemName) {
                              setissubitem({});
                            }
                          });
                          newOpenObject[item.itemName] =
                            !prevOpenObject[item.itemName];
                          if (newOpenObject[item.itemName]) {
                            newOpenObject[item.itemName] = true;
                          }

                          return newOpenObject;
                        });
                      }}
                      selected={isDrawerItemActive(item.itemLink)}
                      className="listitems"
                    >
                      <NavLink
                        to={item.itemLink}
                        style={{ ...listbutton, display: "flex" }}
                        onClick={(event) => {
                          setSelectionChangedCount(selectionChangedCount + 1);
                        }}
                        className={({ isActive }) => {
                          return isActive ? "nav-link active" : "nav-link";
                        }}
                      >
                        <div className="subitem-names">
                          <item.ItemIcon />
                        </div>
                        {item.itemName}
                      </NavLink>
                    </ListItemButton>
                  )}
                </div>
              </CheckPrivilege>
            ))}
          </List>
        </Box>
      </Drawer>
      <Box className="header-box">
        <div className="headers" />
        {location.pathname !== "/dashboard" ? (
          <div className="headers-box">
            <div className="headers-outlet">
              <Outlet />
            </div>
          </div>
        ) : (
          <div className="outer-card">
            <Outlet />
          </div>
        )}
      </Box>
    </div>
  );
}
