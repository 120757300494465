import React, { useRef, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import EmailTemplateEditor from "./EmailTemplateEditor";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Radio from "@mui/material/Radio";
import "./email-templates.css";

import {
  fetchEmailTemplate,
  updateIsCustomTemplateUsed,
  updateCustomEmailTemplate,
  createCustomTemplate
} from "../../API/emailTemplates";
import PageTitle from "../utility/PageTitle";
import { EMAIL_TEMPLATE_NAMES } from "../../utils/Constants";

function EmailTemplateEditorPage({ setFeedback }) {
  const location = useLocation();
  const navigate = useNavigate();
  const editorRef = useRef(null);
  const [template, setTemplate] = useState("");
  const [isCustomTemplateUsed, setIsCustomTemplateUsed] = useState(false);
  const [isCustomTemplateAvailable, setIsCustomTemplateAvailable] =
    useState(false);
  const queryParams = new URLSearchParams(location.search);
  const templateCategoryId = queryParams.get("id");
  const templateCategoryName = queryParams.get("name");
  useEffect(() => {
    fetchEmailTemplate(templateCategoryId)
      .then(({ data }) => {
        setTemplate(data["template"]);
        setIsCustomTemplateUsed(data["isCustomTemplateUsed"]);
        setIsCustomTemplateAvailable(
          data["isCustomTemplateUsed"] == null ? false : true
        );
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "Something went wrong",
          state: true
        });
      });
  }, []);

  const handleTemplateOptionChange = (event) => {
    const data = {
      isCustomTemplateUsed:
        event.target.value == "custom-template" ? true : false
    };
    updateIsCustomTemplateUsed(templateCategoryId, data)
      .then(({ data }) => {
        setIsCustomTemplateUsed(data["isCustomTemplateUsed"]);
        setTemplate(data["template"]);
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "Couldn't select template. Something went wrong.",
          state: true
        });
      });
  };
  const saveCustomTemplate = () => {
    if (editorRef.current) {
      const data = {
        template: editorRef.current.getContent()
      };
      updateCustomEmailTemplate(templateCategoryId, data)
        .then(() => {
          setFeedback({
            severity: "success",
            message: "Template saved successfully.",
            state: true
          });
        })
        .catch((error) => {
          setFeedback({
            severity: "error",
            message: "Something went wrong",
            state: true
          });
        });
    }
  };

  const addCustomTemplate = (event) => {
    const data = {
      templateCategoryId: templateCategoryId,
      template: ""
    };
    createCustomTemplate(data)
      .then(({ data }) => {
        setIsCustomTemplateUsed(true);
        setTemplate(data["template"]);
        setIsCustomTemplateAvailable(true);
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "Couldn't select template. Something went wrong.",
          state: true
        });
      });
  };

  const goBack = () => {
    navigate("/customization/email-templates");
  };

  return (
    <>
      <PageTitle />
      <div className="custom-header">
        <div className="back-button" onClick={goBack}>
          <IconButton aria-label="delete" className="icon-float-left">
            <ArrowBackIcon fontSize="medium" />
          </IconButton>
          <span>Back</span>
        </div>
      </div>
      <h6>Configure {EMAIL_TEMPLATE_NAMES[templateCategoryName]} Template</h6>
      <br />
      <div className="custom-radio-group">
        <Radio
          checked={!isCustomTemplateUsed}
          onChange={handleTemplateOptionChange}
          value="default-template"
          name="select-template-buttons"
          inputProps={{ "aria-label": "A" }}
          id="default-template"
        />
        <label htmlFor="default-template">Use Default Template</label>
        &emsp;
        <Radio
          checked={isCustomTemplateUsed}
          onChange={
            isCustomTemplateAvailable
              ? handleTemplateOptionChange
              : addCustomTemplate
          }
          value="custom-template"
          name="select-template-buttons"
          inputProps={{ "aria-label": "B" }}
          id="custom-template"
        />
        <label htmlFor="custom-template">Use Customized Template</label>
      </div>
      <br />
      <EmailTemplateEditor
        prevContent={template}
        editorRef={editorRef}
        isCustomTemplateUsed={isCustomTemplateUsed}
      />
      <br />
      {isCustomTemplateUsed && (
        <button
          className="btn btn-primary"
          type="button"
          onClick={saveCustomTemplate}
        >
          Save
        </button>
      )}
    </>
  );
}

export default EmailTemplateEditorPage;
