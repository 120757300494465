const ACTION = {
  BLOCK: "BLOCK",
  NOTIFY: "NOTIFY",
  LOG: "LOG",
  QUARANTINE: "QUARANTINE",
  ALLOW: "ALLOW",
  NONE: "NONE"
};

const RISK_LEVEL = {
  LOW: "LOW",
  MEDIUM: "MEDIUM",
  HIGH: "HIGH"
};

export const REMOTE_LOCK_STATUS = {
  APPLIED: "APPLIED",
  REQUESTED: "REQUESTED"
};

export const REMOTE_WIPE_STATUS = {
  APPLIED: "APPLIED",
  REQUESTED: "REQUESTED"
};

const CRITERIA_TYPE = {
  REGEX: "REGEX",
  KEYWORD: "KEYWORD",
  PREDEFINED_ALGORITHM: "PREDEFINED_ALGORITHM"
};

const APPROVAL_STATUS = {
  EXPIRED: "Expired",
  REVOKED: "Revoked",
  ACTIVE: "Active",
  UPCOMING: "Upcoming"
};

const FEATURES = {
  USB: "USB",
  ETHERNET: "ETHERNET",
  CLIPBOARD: "CLIPBOARD",
  SCREENSHOT: "SCREENSHOT",
  SITE_BLOCKING: "SITE_BLOCKING",
  APP_BLOCKING: "APP_BLOCKING",
  EMAIL_BLOCKING: "EMAIL_BLOCKING",
  PASSWORD_CONFIGURATION: "PASSWORD_CONFIGURATION",
  SMTP_CONFIGURATION: "SMTP_CONFIGURATION",
  ACTIVE_DIRECTORY: "ACTIVE_DIRECTORY",
  CLASSIFICATION: "CLASSIFICATION",
  USERS: "USERS",
  DEVICES: "DEVICES",
  GENERAL: "GENERAL",
  MANAGE_ROLES_AND_USERS:"MANAGE_ROLES_AND_USERS"
};

const GROUP_TYPE = {
  DEVICE: "DEVICE"
};

const EVENT_TYPE = {
  SIGN_IN: "SIGN_IN",
  SIGN_OUT: "SIGN_OUT"
};

const EMAIL_TEMPLATE_NAMES = {
  PASSWORD_RESET_EMAIL: "Password Reset Email",
  TIME_BOUND_ACCESS_APPROVED: "Time Bound Access Approved",
  TIME_BOUND_ACCESS_REVOKED: "Time Bound Access Revoked",
  EMAIL_POLICY_BREACHED:"Email Policy Breach",
  DEVICE_POLICY_BREACHED:"Device Policy Breach"
};

const DIALOG_TYPES = {
  ALERT: "ALERT",
  CONFIRM: "CONFIRM",
  INPUT: "INPUT"
};

const DEVICE_TYPE = {
  BYOD: "BYOD",
  COMPANY_OWNED: "COMPANY_OWNED"
};

const MESSAGES = {
  LOGOUT_MESSAGE: "You have been logged out. Please log in again to continue !",
  GENERIC_EXCEPTION_MESSAGE: "Something went wrong",
  INVALID_SUBDOMAIN: "Invalid Subdomain",
  NOT_ALLOWED:"You are not allowed to perform this action."
};
const AGENT_STATUS = {
  STARTED: "Started",
  STOPPED: "Stopped",
  UNINSTALLED: "Uninstalled"
};


const DESTINATION_ID_TO_NAME = {
  1: "USB",
  2: "EMAIL",
  3: "HTTP",
  6: "DESKTOP APPS",
  7: "ETHERNET",
  8: "PRINTER"
};

const JOB_NAMES = {
  IMPORT_END_USER: "IMPORT_END_USER",
  IMPORT_DOMAIN_AND_CATEGORIES: "IMPORT_DOMAIN_AND_CATEGORIES"
};

const JOB_HEADINGS = {
  IMPORT_END_USER: "Bulk user CSV upload",
  IMPORT_DOMAIN_AND_CATEGORIES: "Bulk domain CSV upload"
};

const usbTypeMap = {
  usb : {
    type: "STORAGE",
    title: "USB Storage Device",
    path: "/usb"
  },
  printer : {
    type: "PRINTER",
    title: "USB Printer",
    path: "/printer"
  },
};

const ROLE_ID_MAP = {
  ADMIN: 1
};


export {
  ACTION,
  RISK_LEVEL,
  CRITERIA_TYPE,
  APPROVAL_STATUS,
  FEATURES,
  EMAIL_TEMPLATE_NAMES,
  DIALOG_TYPES,
  DEVICE_TYPE,
  MESSAGES,
  DESTINATION_ID_TO_NAME,
  GROUP_TYPE,
  EVENT_TYPE,
  JOB_HEADINGS,
  JOB_NAMES,
  AGENT_STATUS,
  usbTypeMap,
  ROLE_ID_MAP
};
