import { useState } from "react";
import TableComponent from "../utility/Table";
import Button from "@mui/material/Button";
import RuleForm from "./RuleForm";
import {
  deleteRule,
  fetchAllCustomDictionaryRulesByCustomerId
} from "../../API/rule";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import SearchBar from "../utility/SearchBar";
import { DIALOG_TYPES } from "../../utils/Constants";
import CustomDialog from "../utility/Dialog";
import Pagination from "../utility/Pagination";
import { PRIVILEGES } from "../../utils/Privileges";

function CustomDictionaryRules({
  setFeedback,
  selectedId,
  setSelectedId,
  setOpenRule,
  fetchCount,
  setFetchCount,
  setIsDefault
}) {
  const [data, setData] = useState(null);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [searchText, setSearchText] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    fetchAllCustomDictionaryRulesByCustomerId(pageSize, pageNumber, searchText)
      .then((response) => {
        setData(response.data.data);
        setTotalCount(response.data.totalCount);
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "There is an issue while fetching rules.",
          state: true
        });
      });
  }, [fetchCount, pageNumber, searchText, pageSize]);

  const handleDelete = (id) => {
    setIsDeleteConfirmationOpen(true);
    setSelectedId(id);
  };

  const handleConfirmDelete = () => {
    deleteRule(selectedId)
      .then((response) => {
        setData(data.filter((rule) => rule.id !== selectedId));
        setFeedback({
          severity: "success",
          message: "Rule deleted successfully",
          state: true
        });
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "Something went wrong",
          state: true
        });
      })
      .finally(() => {
        setIsDeleteConfirmationOpen(false);
        setSelectedId("");
      });
  };

  const handleView = (id) => {
    setIsDefault(false);
    setOpenRule(true);
    setSelectedId(id);
  };

  const headings = ["id", "name"];

  return (
    <div>
      <div style={{ display: "flex" }}>
        <SearchBar
          setSearchText={setSearchText}
          searchText={searchText}
          placeholder={"Search by Rule Name"}
        />
      </div>
      <TableComponent
        headings={headings}
        data={data}
        actions={[
          {
            label: "Edit",
            onClickHandler: handleView,
            hasParameter: true,
            requiredPrivileges: [PRIVILEGES.EDIT_RULE.name]
          },
          {
            label: "Delete",
            onClickHandler: handleDelete,
            hasParameter: true,
            requiredPrivileges: [PRIVILEGES.DELETE_RULE.name]
          }
        ]}
        options={{ hideIds: true }}
        styles={{
          table: { maxHeight: 395 }
        }}
        customColumnNames={{
          name: "Rule Name"
        }}
      />
      {totalCount != 0 ? (
        <div className="pagination">
          <Pagination
            totalCount={totalCount}
            page={pageNumber}
            setPage={setPageNumber}
            rowsPerPage={pageSize}
            setRowsPerPage={setPageSize}
          />
        </div>
      ) : null}
      <CustomDialog
        open={isDeleteConfirmationOpen}
        title="Confirm Deletion"
        content="Are you sure you want to delete?"
        setOpen={setIsDeleteConfirmationOpen}
        handleConfirm={handleConfirmDelete}
        dialogType={DIALOG_TYPES.CONFIRM}
      />
    </div>
  );
}

export default CustomDictionaryRules;
