import React from "react";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import { useState } from "react";
import RuleForm from "../rule/RuleForm";
import { fetchAndFormatDictionaryRules } from "../../utils/Helpers";
import {
  Divider,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Toolbar,
  InputLabel,
  TextField,
  List,
  Grid,
  Fade
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import * as Yup from "yup";
import { useEffect } from "react";
import {
  createClassification,
  fetchClassification,
  updateClassification
} from "../../API/classification";
import "./classification.css";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import CheckboxSelectorListWithSearch from "../utility/CheckBoxSelectorListWithSearch";
import FormFooter from "../utility/FormFooter";
import CustomTooltip from "../../utils/Tooltip";

function ClassificationForm({
  open,
  setOpen,
  setFeedback,
  selectedClassificationId,
  setFetchCount
}) {
  const [openRule, setOpenRule] = useState(false);
  const [dictionaryRuleIds, setDictionaryRuleIds] = useState([]);
  const [customRuleList, setCustomRuleList] = useState([]);
  const [selectedRule, setSelectedRule] = useState(null);

  let formType = selectedClassificationId ? "Update" : "Add";

  useEffect(() => {
    if (selectedClassificationId) {
      fetchClassification(selectedClassificationId)
        .then((response) => {
          formik.setValues(response.data);
          setCustomRuleList(response.data.customRuleList);
          setDictionaryRuleIds(response.data.dictionaryRuleIds);
        })
        .catch((error) =>
          setFeedback({
            severity: "error",
            message: "There is an issue while fetching the classification.",
            state: true
          })
        );
    } else {
      formik.resetForm();
      setCustomRuleList([]);
      setDictionaryRuleIds([]);
      setSelectedRule(null);
    }
  }, [selectedClassificationId, open]);

  const handleClose = () => {
    setOpen(false);
  };

  const validate = Yup.object({
    name: Yup.string().required("Required"),
    description: Yup.string().required("Required")
  });

  function submitForm(values, resetForm) {
    const formValues = {
      ...values,
      customRuleList: customRuleList,
      dictionaryRuleIds: dictionaryRuleIds
    };
    let promise;
    if (formType === "Add") {
      promise = createClassification(formValues);
    } else {
      promise = updateClassification(formValues);
    }

    promise
      .then((response) => {
        const message = formType === "Add" ? "added" : "updated";
        setFeedback({
          severity: "success",
          message: `Classification ${message} successfully`,
          state: true
        });
        setFetchCount((fetchCount) => fetchCount + 1);
        setOpen(false);
      })
      .catch(function (error) {
        setFeedback({
          severity: "error",
          message: error.response.data,
          state: true
        });
      });
  }

  const formik = useFormik({
    initialValues: {
      id: "",
      name: "",
      description: ""
    },
    validationSchema: validate,
    onSubmit: (values, { resetForm }) => {
      submitForm(values, resetForm);
    }
  });

  const handleCreateNewRule = () => {
    setOpenRule(true);
    setSelectedRule(null);
  };

  const handleDelete = (name) => {
    setCustomRuleList(
      customRuleList.filter((customRule) => customRule.name !== name)
    );
  };

  const handleEdit = (name) => {
    setOpenRule(true);
    setSelectedRule(
      customRuleList.find((customRule) => customRule.name === name)
    );
  };

  return (
    <div
      className={`drawer-form-wrapper ${
        open ? "drawer-form-open" : "drawer-form-closed"
      }`}
      style={{ width: "65%" }}
    >
      <div>
        <Toolbar />
        <br />
        <div className="close-button">
          <IconButton onClick={handleClose} sx={{ padding: 0 }}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="drawer-form-container">
          <h5>{formType} Classification</h5>
          <Divider sx={{ opacity: 1 }} />
          <form>
            <Grid
              container
              spacing={2}
              className="group-form-container"
              columns={24}
            >
              <Grid item md={12}>
                <div className="form-input">
                  <InputLabel
                    htmlFor="name"
                    className="form-input-label"
                    required
                  >
                    Name
                    <CustomTooltip
                    title="The classification name is required to identify this classification.">
                    </CustomTooltip>
                  </InputLabel>
                  <TextField
                    size="small"
                    id="name"
                    placeholder="Name"
                    className="form-textfield"
                    fullWidth
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </div>
                <div className="form-input">
                  <InputLabel
                    htmlFor="description"
                    className="form-input-label"
                    required
                  >
                    Description
                    <CustomTooltip
                    title="Provide essential details to describe this classification for future reference.">
                    </CustomTooltip>
                  </InputLabel>
                  <TextField
                    multiline
                    rows={2}
                    size="small"
                    id="description"
                    placeholder="Description"
                    className="form-textfield"
                    fullWidth
                    name="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.description &&
                      Boolean(formik.errors.description)
                    }
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                  />
                </div>
                {customRuleList.length > 0 ? (
                  <>
                    <InputLabel className="form-input-label">
                      Custom Rules
                    </InputLabel>
                    <Divider sx={{ opacity: 1 }} />
                    <List dense>
                      {customRuleList.map((customRule) => (
                        <ListItem>
                          <ListItemText primary={customRule.name} />
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => handleEdit(customRule.name)}
                            >
                              <EditIcon />
                            </IconButton>
                            &emsp;
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => handleDelete(customRule.name)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </List>
                    <Divider sx={{ opacity: 1 }} />
                    <br />
                  </>
                ) : null}
                <Button
                  onClick={handleCreateNewRule}
                  startIcon={<AddCircleIcon />}
                >
                  Add Custom Rule
                </Button>
              </Grid>
              <Grid md={1} item sx={{ Height: "100%" }}>
                <Divider orientation="vertical" sx={{ opacity: 1 }} />
              </Grid>
              <Grid item md={11}>
                <br />
                <CheckboxSelectorListWithSearch
                  heading={"Select Rules From Dictionary"}
                  setFeedback={setFeedback}
                  fetchAndFormatFields={fetchAndFormatDictionaryRules}
                  selectedRecords={dictionaryRuleIds}
                  setSelectedRecords={setDictionaryRuleIds}
                  options={{
                    showFilters: true,
                    showSearchBar: true
                  }}
                  maxHeight="350px"
                />
              </Grid>
            </Grid>
            <FormFooter
              setOpen={setOpen}
              formType={formType}
              handleSubmit={formik.handleSubmit}
              handleClose={handleClose}
            />
          </form>
        </div>
      </div>
      <div>
        <RuleForm
          openRule={openRule}
          setOpenRule={setOpenRule}
          customRuleList={customRuleList}
          setCustomRuleList={setCustomRuleList}
          setFeedback={setFeedback}
          selectedRule={selectedRule}
        />
      </div>
    </div>
  );
}

export default ClassificationForm;
