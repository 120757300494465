import PageTitle from "../utility/PageTitle";
import { Box } from "@mui/material";
import NavigationTabs from "../utility/NavigationTabs";
import { Outlet } from "react-router-dom";
import { PRIVILEGES } from "../../utils/Privileges";
function ManageRolesAndUsers({ setFeedback }) {
  const tabList = [
    {
      label: "Roles",
      path: "/manage/role"
    },
    {
      label: "Privileged Users",
      path: "/manage/user"
    }
  ];
  return (
    <div>
      <PageTitle
        subheading={
          "Create roles and privileged users to manage dashboard activity"
        }
      />
      <br />
      <NavigationTabs tabList={tabList} />
      <Box sx={{ pt: 2 }}>
        <Outlet />
      </Box>
    </div>
  );
}

export default ManageRolesAndUsers;
