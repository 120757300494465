import { useSelector } from "react-redux";

export function CheckPermission({ requiredPrivileges, requiredAll = false }) {
  const userPrivileges = useSelector((state) => state.auth.privileges);  
  const hasPermission =
    requiredPrivileges == undefined || requiredPrivileges.length == 0 ||
    (requiredAll
      ? requiredPrivileges.every((privilege) => userPrivileges[privilege]) // All privileges required
      : requiredPrivileges.some((privilege) => userPrivileges[privilege])); // At least one privilege required

  return hasPermission;
}
