import React from "react";
import { useSelector } from "react-redux";
import { Container, Avatar } from "@mui/material";
import stringAvatar from "./utility/Avatar";
import PageTitle from "./utility/PageTitle";

function AccountPage() {
  const user = useSelector((state) => state.auth.currentUser);

  return (
    <>
      <PageTitle />
      <Container className="custom-display-flex">
        <header className="custom-padding">
          <Avatar
            {...stringAvatar(
              user.email,
              user.firstName[0],
              user.lastName[0],
              100
            )}
          />
        </header>

        <div className="custom-align-left">
          <b>Personal details</b>

          <div className="custom-margin-top">
            <b>Name</b>
            <div>
              {user.firstName} {user.lastName}
            </div>
          </div>
          <div className="custom-margin-top">
            <b>Email</b>
            <div>{user.email}</div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default AccountPage;
