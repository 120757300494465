import Alert from "@mui/material/Alert";
import PropTypes from "prop-types";

export default function CustomAlert({ type = "info", text = "", styles = {} }) {
  return (
    <Alert severity={type} sx={styles}>
      {text}
    </Alert>
  );
}

CustomAlert.propTypes = {
  type: PropTypes.oneOf(["error", "warning", "info", "success"]),
  text: PropTypes.string.isRequired,
  styles: PropTypes.object
};
