import React from "react";
import unauthorized from "../unauthorized.svg";

export default function UnauthorizedAccess({ height = "45%", width = "45%" }) {
  return (
    <div>
      <img src={unauthorized} height={height} width={width} />
    </div>
  );
}
