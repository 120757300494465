import Box from "@mui/material/Box";
import PageTitle from "../utility/PageTitle";
import NavigationTabs from "../utility/NavigationTabs";
import { Outlet } from "react-router-dom";
import { PRIVILEGES } from "../../utils/Privileges";

function DesktopAppPolicy({ setFeedback }) {
  const tabList = [
    {
      label: "All Applications",
      path: "/desktop-app",
      requiredPrivileges: [PRIVILEGES.VIEW_APP.name]
    },
    {
      label: "Application Category",
      path: "/desktop-app/category",
      requiredPrivileges: [PRIVILEGES.VIEW_APP_CATEGORY.name]
    },
    {
      label: "Policy",
      path: "/desktop-app/policy",
      requiredPrivileges: [PRIVILEGES.VIEW_POLICY.name]
    }
  ];

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <PageTitle subheading="Manage Application Categories and Restrict Use of Unwanted Applications in Your Organization" />
        <br />
        <NavigationTabs tabList={tabList} />
        <Box sx={{ pt: 2 }}>
          <Outlet />
        </Box>
      </Box>
    </div>
  );
}

export default DesktopAppPolicy;
